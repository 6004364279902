<template>
  <div class="manage-user-info" >
    <div class="table-header">
      <div class="query-item">
        <span class="query-item-label">ID: </span>
        <el-input class="query-item-input" v-model="queryParams.id" />
      </div>
      <div class="query-item">
        <span class="query-item-label">工具会员: </span>
        <el-select  v-model="queryParams.vipType" class=" query-item-input m-2" clearable  placeholder="选择">
          <el-option :key="0" label="非开通" :value="0" />
          <el-option :key="1" label="试用会员" :value="1" />
          <el-option :key="2" label="正式会员" :value="2" />
        </el-select>
      </div>
      <div class="query-item">
        <span class="query-item-label">是否管理员: </span>
        <el-select  v-model="queryParams.isManager" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option :key="1" label="是" :value="1" />
          <el-option :key="0" label="否" :value="0" />
        </el-select>
      </div>
      <div class="query-item">
        <span class="query-item-label">开通小程序: </span>
        <el-select  v-model="queryParams.hasOpenidXcx" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option :key="1" label="是" :value="1" />
          <el-option :key="0" label="否" :value="0" />
        </el-select>
      </div>
      <div class="query-item">
        <span class="query-item-label">是否注销: </span>
        <el-select  v-model="queryParams.isDelete" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option :key="1" label="是" :value="1" />
          <el-option :key="0" label="否" :value="0" />
        </el-select>
      </div>
      <div class="btn-group">
        <el-button type="primary" @click="searchBtn">查询</el-button>
        <el-button @click="resetBtn">重置</el-button>
      </div>

    </div>
    <div class="table-content custom-overflow">
      <el-table :data="pageData.rows" stripe style="width: 100%">
        <el-table-column prop="id" label="ID" width="80" />
        <el-table-column prop="name" label="用户名" width="180" />
        <el-table-column prop="vip_type_enum" label="会员类型" width="100"  />
        <el-table-column prop="vip_expire_time" label="会员时间"  width="100" />
        <el-table-column prop="openid_xcx_enum" label="小程序" width="80"  />
        <el-table-column prop="is_delete_enum" label="是否注销" width="80"  />
        <el-table-column prop="create_time" label="创建时间" />
        <el-table-column prop="update_time" label="更新时间" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-button size="small" type="primary" @click="showOrderInfoList(scope.$index, scope.row)">订单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="total">记录数: {{pageData.total}}条</div>
        <el-pagination background layout="prev, pager, next" :page-size="pageData.pageSize" :current-page="pageData.currentPage" :total="pageData.total" @current-change="currentChange" />
      </div>
    </div>
  </div>

  <el-dialog
    v-model="openOrderInfoFlag"
    title="订单信息"
    class="order-info-dialog"
  >
    <ManageOrderInfoByUser v-if="openOrderInfoFlag" :userId="orderInfoUserId"></ManageOrderInfoByUser>
  </el-dialog>
</template>

<script>

import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'
import ManageOrderInfoByUser from "./ManageOrderInfoByUser";
export default {
  name: 'ManageUserInfo',
  components: {
    ManageOrderInfoByUser

  },
  data () {
    return {
      queryParams: {
        id: '',
        vipType: '',
        isManager: '',
        hasOpenidXcx: '',
        isDelete: '',
      },
      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      openOrderInfoFlag: false,
      orderInfoUserId: '',
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      this.searchBtn();
    },
    resetBtn(){
      this.queryParams =  {
        id: '',
        vipType: '',
        isManager: '',
        hasOpenidXcx: '',
        isDelete: '',
      }
    },
    // 查询
    async searchBtn(){
      let res = await nodeServer.userInfo_getPage({
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        id: this.queryParams.id,
        vipType: this.queryParams.vipType,
        isManager: this.queryParams.isManager,
        hasOpenidXcx: this.queryParams.hasOpenidXcx,
        isDelete: this.queryParams.isDelete,
      });
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          row.vip_expire_time = dateUtils.getDateString(new Date(row.vip_expire_time));
          row.create_time = dateUtils.getDateTimeString(new Date(row.create_time));
          row.update_time = dateUtils.getDateTimeString(new Date(row.update_time));
          // 会员类型 vip_type 0: 无, 1: 试用, 2: 正式
          let vipTypeMap = {0: '无', 1: '试用会员', 2: '正式会员'}
          row.vip_type_enum = vipTypeMap[row.vip_type];
          // 是否注销 is_delete 0: 否, 1: 是
          let isDeleteMap = {0: '否', 1: '是'}
          row.is_delete_enum = isDeleteMap[row.is_delete];
          // 是否使用小程序
          row.openid_xcx_enum = '-'
          if(row.openid_xcx){
            row.openid_xcx_enum = '开通'
          }
        }

        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.searchBtn()
    },
    showOrderInfoList(index, row){
      this.orderInfoUserId = row.id;
      this.openOrderInfoFlag = true;

    }
  }
}
</script>
<style lang="scss" scoped>
.manage-user-info{
  width: 100%;
  height: 100%;
  .table-header{
    height: 120px;
    line-height: 60px;
    display: flex;
    flex-wrap: wrap;
    .query-item{
      margin-left: 64px;
      .query-item-label{
        text-align: right;
      }
      .query-item-input{
        margin-left: 16px;
        width: 120px;
      }
    }
    .btn-group{
      margin-left: 32px;
      .el-button{
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
  .table-content{
    height: calc(100% - 120px);
    overflow-y: auto;
    .pagination{
      margin-top: 1rem;
      position: relative;
      float: right;
      display: flex;
      .el-pagination{
        margin-right: 32px;
      }
      .total{
        line-height: 40px;
        font-size: 14px;
        margin-right: 24px;
      }
    }
  }
}
</style>
<style lang="scss">
  .order-info-dialog{
    width: 1200px !important;
    .el-dialog__body{
      padding: 0;
    }
  }
</style>
