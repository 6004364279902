import { createApp } from 'vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/rem';
import './assets/styles/index.scss'
import './assets/styles/reset.css'


// 引入element组件
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
// 引入element图标库
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 引入iconfont
import './assets/iconfont/iconfont.css'

const app = createApp(App)

app.use(store)
app.use(router)



// 使用element组件
app.use(ElementPlus, {
  locale: zhCn,   //默认中文
})
// 使用element图标库
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.mount('#app')

window.app = app
