
<template>
  <div class="manage-interact-Article-info" >
    <div class="article-content custom-overflow">
      <el-form :model="entityInfo" label-width="auto" :rules="entityRules" @submit.native.prevent ref="CreateInfoFormRef" >
        <div class="info-group">
          <div class="info-row">
            <el-form-item class="info-item" label="标题" prop="title">
              <el-input class="info-item-input" v-model="entityInfo.title" />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="类型" prop="interact_type">
              <el-cascader v-model="entityInfo.interact_type_list" :options="interactTypeTree"
                           :props="{ checkStrictly: false, value: 'name', label: 'name', multiple: true}"
                           placeholder="请选择文章类型(可多选)" clearable />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="内容" prop="content">
              <QuillEditorInfo ref="QuillEditorContentInfo" class="custom-editor"></QuillEditorInfo>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="btn-group" v-loading="saveLoading">
        <el-button type="default" @click="saveQuestionInfoBtn(0)" >暂存文章</el-button>
        <el-button type="primary" @click="commitQuestionInfoBtn" >提交文章</el-button>
      </div>    </div>
  </div>

</template>

<script>

import { ElMessage, ElMessageBox } from 'element-plus'
import nodeServerInteract from "@/net/nodeServerInteract";
import QuillEditorInfo from '@/views/QuillEditorInfo.vue'

export default {
  name: 'ManageInteractArticleInfo',
  components: {
    QuillEditorInfo

  },
  data () {
    return {
      interactTypeTree: [],
      entityInfo: {
        id: null,
        title: '',
        interact_type: '',
        interact_type_list: [],
        content: ''
      },
      entityRules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { max: 50, message: '最大长度50', trigger: 'blur' },
        ],
      },
      saveLoading: false,
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    async init () {
      await this.initTypeTree();
      // 获取暂存的文章
      await this.getOneArticle0ByUser();
    },
    async initTypeTree(){
      let res = await nodeServerInteract.interactTypeInfo_getTypeTree({});
      if(res.success && res.content){
        this.interactTypeTree = res.content;
      }
    },
    async getOneArticle0ByUser(){
        let res = await nodeServerInteract.interactQuestionInfo_getOneArticle0ByUser({});
        if(res && res.success && res.content){
          this.entityInfo = res.content;
          // 设置类型下拉框
          this.entityInfo.interact_type_list = this.handleInteractTypeToList(this.entityInfo.interact_type);
          // 设置富文本框内容
          this.$refs.QuillEditorContentInfo.setEditorContent(this.entityInfo.content);

        }
    },
    /**
     * 保存或者更新文章
     * @param examine_status 0: 未审核(暂存), 1: 审核通过(提交)
     * @returns {Promise<void>}
     */
    async saveQuestionInfoBtn(examine_status = 0){
      this.saveLoading = true;
      // 获取内容
      let interact_type = '';
      if(this.entityInfo.interact_type_list && this.entityInfo.interact_type_list.length > 0){
        interact_type = this.handleInteractType(this.entityInfo.interact_type_list);
      }
      let content = this.$refs.QuillEditorContentInfo.getEditorContent();
      this.entityInfo.content = content;


      let entity = {
        id: this.entityInfo.id,
        title: this.entityInfo.title,
        content: this.entityInfo.content,
        interact_type: interact_type,
        examine_status: examine_status
      }
      let res = await nodeServerInteract.interactQuestionInfo_createOrUpdateArticle(entity);
      let message = examine_status === 0 ? '暂存' : '提交';
      if(res.success){
        ElMessage.success(`${message}成功`);
        // 设置entityId
        this.entityInfo.id = res.content.id;
        if(examine_status === 1){
          // 已经提交， 清空表单
          this.entityInfo = {
            id: null,
            title: '',
            interact_type: '',
            interact_type_list: [],
            content: ''
          }
          // 清空富文本内容
          this.$refs.QuillEditorContentInfo.clearEditorContent();
        }
      }else{
        ElMessage.error(`${message}失败`);
      }

      this.saveLoading = false;
    },
    async commitQuestionInfoBtn(){
      const myComponentInstance = this.$refs.CreateInfoFormRef;
      if (!myComponentInstance) return
      await myComponentInstance.validate((valid, fields) => {
        if (valid) {
          this.commitQuestionConfirm();
        } else {
          ElMessage.error('请先完成信息填写');
        }
      })
    },
    commitQuestionConfirm(){
      ElMessageBox.confirm(
        '请确认是否提交文章，提交后将不能修改',
        '提交文章',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          // 调用接口提交
          this.saveQuestionInfoBtn(1);
        })
        .catch(() => {
          // 取消提交
        })
    },
    handleInteractType(list){
      let resultList = [];
      for(let list1 of list){
        resultList.push(list1.join('-'));
      }
      let result = resultList.join(',');
      return result;
    },
    handleInteractTypeToList(interactType){
      let resultList2 = [];
      if(!interactType){
        return resultList2;
      }
      let resultList1 = [];
      resultList1 = interactType.split(',')
      for(let item of resultList1){
        resultList2.push(item.split('-'));
      }
      return resultList2;
    }
  }
}
</script>
<style lang="scss" scoped>
.manage-interact-Article-info{
  width: 100%;
  height: 100%;
  .article-content{
    height: calc(100%);
    overflow-y: auto;
    .info-group{
      .info-group-title{
        height: 24px;
        line-height: 22px;
        font-size: 14px;
        border-bottom: 1px solid #8c8c8c;
        margin-bottom: 16px;
      }
      .info-row{
        display: flex;
        .info-item{
          flex: 1;
          margin-right: 32px;
          .tag-input, .keyword-button-new-tag{
            width: 160px !important;
          }
          .el-tag{
            margin-right: 8px;
            margin-bottom: 4px;
          }
          .tip-text{
            font-size: 12px;
            margin-left: 12px;
            color: #8c8c8c;
          }
          .custom-editor{
            width: 100%;
          }
        }
      }
    }
    .custom-editor{
      width: 100%;
    }
    .btn-group{
      text-align: center;
      margin-left: 16px;
      line-height: 64px;
      .el-button{
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
}
</style>
