<template>
  <div class="release-personal-center">
    <div class="content">
      <div class="content-top">
        <div class="content-base-info" v-loading="baseInfoLoading">
          <div class="content-custom-btn" @click="clickBaseShowBtn">
            <span>基础信息</span>
            <el-icon><CaretRight /></el-icon>
          </div>
          <div class="content-custom-examine-status" title="基础信息初始审核情况" v-if="this.baseInfo.id">
            <el-icon class="examine-status-icon examine-status-todo" v-if="this.baseInfo.examine_status == 0"><WarningFilled /></el-icon>
            <el-icon class="examine-status-icon examine-status-pass" v-else-if="this.baseInfo.examine_status == 1"><SuccessFilled /></el-icon>
            <el-icon class="examine-status-icon examine-status-no-pass" v-else :title="showBaseAuditInfo.examine_message"><WarningFilled /></el-icon>
            {{ this.getDictNameByDictCode('release_examine_status', this.baseInfo.examine_status) }}
          </div>
          <div class="content-base-info-left" v-if="this.baseInfo.id">
            <div class="content-base-info-left-image">
              <div class="content-base-info-left-image-info">
                <i class="iconfont icon-yonghufill"></i>
              </div>
              <div class="content-base-info-left-image-sex" :class="{sex_nv: this.baseInfo.sex == '2'}">
                <i class="iconfont icon-xingbienv" v-if="this.baseInfo.sex == '2'"></i>
                <i class="iconfont icon-xingbienan" v-else></i>
              </div>
            </div>
          </div>
          <div class="content-base-info-right" v-if="this.baseInfo.id">
            <div class="content-base-info-right-item">
              <div class="right-item-name">
                {{this.baseInfo.name}}
              </div>
              <el-icon class="examine-status-icon"
                       :class="{'examine-status-todo': this.showBaseAuditInfo.examine_status == 0, 'examine-status-no-pass': this.showBaseAuditInfo.examine_status == 2}"
                       v-if="this.checkHasChangeColumn(this.showBaseAuditInfo, 'name')"
                       :title="this.getUpdateAuditTitleMessage(this.showBaseAuditInfo, 'name')">
                <WarningFilled />
              </el-icon>
            </div>
            <div class="content-base-info-right-item">
              <div class="right-item-wechat">
                <i class="iconfont icon-weixin"></i>
                <span>{{ this.baseInfo.wechat ? this.baseInfo.wechat : '暂无' }}</span>
              </div>
              <el-icon class="examine-status-icon"
                       :class="{'examine-status-todo': this.showBaseAuditInfo.examine_status == 0, 'examine-status-no-pass': this.showBaseAuditInfo.examine_status == 2}"
                       v-if="this.checkHasChangeColumn(this.showBaseAuditInfo, 'wechat')"
                       :title="this.getUpdateAuditTitleMessage(this.showBaseAuditInfo, 'wechat')">
                <WarningFilled />
              </el-icon>
            </div>
            <div class="content-base-info-right-item">
              <div class="right-item-phone-number">
                <i class="iconfont icon-dianhua"></i>
                <span>{{ this.baseInfo.phone_number ? this.baseInfo.phone_number : '暂无' }}</span>
              </div>
              <el-icon class="examine-status-icon"
                       :class="{'examine-status-todo': this.showBaseAuditInfo.examine_status == 0, 'examine-status-no-pass': this.showBaseAuditInfo.examine_status == 2}"
                       v-if="this.checkHasChangeColumn(this.showBaseAuditInfo, 'phone_number')"
                       :title="this.getUpdateAuditTitleMessage(this.showBaseAuditInfo, 'phone_number')">
                <WarningFilled />
              </el-icon>
            </div>
          </div>
          <div class="content-nodata"  v-if="!this.baseInfo.id">
            <div class="content-nodata-btn" @click="clickBaseShowBtn">
              <el-icon><Plus /></el-icon>
              <span>添加基础信息</span>
            </div>
          </div>
        </div>
        <div class="content-staff-info" v-loading="staffInfoLoading">
          <div class="content-custom-btn" @click="clickStaffShowBtn">
            <span>个人履历</span>
            <el-icon><CaretRight /></el-icon>
          </div>
          <div class="content-custom-examine-status" title="个人履历初始审核情况" v-if="staffInfo.id">
            <el-icon class="examine-status-icon examine-status-todo" v-if="this.staffInfo.examine_status == 0"><WarningFilled /></el-icon>
            <el-icon class="examine-status-icon examine-status-pass" v-else-if="this.staffInfo.examine_status == 1"><SuccessFilled /></el-icon>
            <el-icon class="examine-status-icon examine-status-no-pass" v-else :title="showStaffAuditInfo.examine_message"><WarningFilled /></el-icon>
            {{ this.getDictNameByDictCode('release_examine_status', this.staffInfo.examine_status) }}
          </div>
          <div class="content-staff-info-top" v-if="staffInfo.id">
            <div class="info-top-item item-letf">
              <div class="info-top-item-row">
                <i class="iconfont icon-dingwei"  style="color: rgb(56,155,255)"></i>
                <span class="item-row-span">{{staffInfo.province_name}}</span>
                <span class="item-row-span">{{staffInfo.city_name ? '-'+staffInfo.city_name : ''}}</span>
              </div>
              <div class="info-top-item-row">
                <el-select  v-model="staffInfo.fullTimeTypeMode" class="m-2" @change="changeFullTimeType">
                  <el-option label="启用-寻找兼职" :value="0"></el-option>
                  <el-option label="启用-寻找全职" :value="1"></el-option>
                  <el-option label="关闭-暂不考虑" :value="2"></el-option>
                </el-select>
              </div>
            </div>
            <div class="info-top-item item-right">
              <div class="info-top-item-row">
                <i class="iconfont icon-xuexiao" style="color: rgb(51,51,51)"></i>
                <span class="item-row-span">{{staffInfo.school}}</span>

                <el-icon class="examine-status-icon"
                         :class="{'examine-status-todo': this.showStaffAuditInfo.examine_status == 0, 'examine-status-no-pass': this.showStaffAuditInfo.examine_status == 2}"
                         v-if="this.checkHasChangeColumn(this.showStaffAuditInfo, 'school')"
                         :title="this.getUpdateAuditTitleMessage(this.showStaffAuditInfo, 'school')">
                  <WarningFilled />
                </el-icon>
              </div>
              <div class="info-top-item-row">
                <i class="iconfont icon-zhengshu" style="color: rgb(254,131,12)"></i>
                <span class="item-row-span">{{staffInfo.certificate}}</span>

                <el-icon class="examine-status-icon"
                         :class="{'examine-status-todo': this.showStaffAuditInfo.examine_status == 0, 'examine-status-no-pass': this.showStaffAuditInfo.examine_status == 2}"
                         v-if="this.checkHasChangeColumn(this.showStaffAuditInfo, 'certificate')"
                         :title="this.getUpdateAuditTitleMessage(this.showStaffAuditInfo, 'certificate')">
                  <WarningFilled />
                </el-icon>
              </div>
            </div>
          </div>
          <div class="content-staff-info-bottom" v-if="staffInfo.id">
            <i class="iconfont icon-jieshao" style="color: rgb(50,153,201)"></i>
            <span class="item-row-span" :title="staffInfo.introduction">{{staffInfo.introduction}}</span>
            <el-icon class="examine-status-icon"
                     :class="{'examine-status-todo': this.showStaffAuditInfo.examine_status == 0, 'examine-status-no-pass': this.showStaffAuditInfo.examine_status == 2}"
                     v-if="this.checkHasChangeColumn(this.showStaffAuditInfo, 'introduction')"
                     :title="this.getUpdateAuditTitleMessage(this.showStaffAuditInfo, 'introduction')">
              <WarningFilled />
            </el-icon>
          </div>

          <div class="content-nodata"  v-if="!this.staffInfo.id">
            <div class="content-nodata-btn" @click="clickStaffShowBtn">
              <el-icon><Plus /></el-icon>
              <span>添加个人履历</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content-bottom">
        <div class="content-job-info">
          <div class="content-custom-btn" @click="clickJobShowBtn">
            <span>发布工作</span>
            <el-icon><CaretRight /></el-icon>
          </div>
          <div class="content-job-header">
            <div class="header-item" :class="{active: this.jobPageData.params.full_time_type == 0}" @click="handleClickJobHeader(0)">
              <span>兼职工作</span>
            </div>
            <div class="header-item" :class="{active: this.jobPageData.params.full_time_type == 1}" @click="handleClickJobHeader(1)">
              <span>全职工作</span>
            </div>
          </div>
          <div class="content-job-list" v-loading="this.jobPageData.loading">
            <div class="content-nodata"  v-if="this.jobPageData.total == 0">
              <div class="content-nodata-btn" @click="clickJobShowBtn">
                <el-icon><Plus /></el-icon>
                <span>暂无数据</span>
              </div>
            </div>
            <template v-for="(item, index) in jobPageData.rows" :key="index" >
              <div class="content-job-item" @click="clickJobShowBtn(item)">
                <div class="content-custom-examine-status" :title="item.examine_status_enum + '\n审核意见：' +item.examine_message" v-if="item.id">
                  <el-icon class="examine-status-icon examine-status-todo" v-if="item.examine_status == 0"><WarningFilled /></el-icon>
                  <el-icon class="examine-status-icon examine-status-pass" v-else-if="item.examine_status == 1"><SuccessFilled /></el-icon>
                  <el-icon class="examine-status-icon examine-status-no-pass" v-else ><WarningFilled /></el-icon>
                  {{ item.examine_status_enum }}
                </div>

                <div class="content-job-item-title">{{ item.title }}</div>
                <div class="content-job-item-row">
                  <i class="iconfont icon-xinzi" style="color: rgb(254, 131, 12)"></i>
                  <span>薪资情况：</span>
                  <span>{{item.salary_enum}}</span>
                </div>
                <!-- 兼职才有项目时间-->
                <div class="content-job-item-row" v-if="item.full_time_type == 0">
                  <i class="iconfont icon-shijian" style="color: rgb(22, 212, 107)"></i>
                  <span>项目时间：</span>
                  <span>{{item.entry_time_enum}}</span>
                  <span>至</span>
                  <span>{{item.end_time_enum}}</span>
                </div>
                <div class="content-job-item-foot">
                  <div class="content-job-item-foot-name">
                    <i class="iconfont icon-yonghufill"></i>
                    <span>{{ item.base_name }}</span>
                  </div>
                  <div class="content-job-item-foot-address">
                    <i class="iconfont icon-dingwei" style="color: rgb(56,155,255)"></i>
                    <span>{{ item.region_name }}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="content-job-pagination">
            <div class="total">记录数: {{jobPageData.total}}条</div>
            <el-pagination background layout="prev, pager, next" :page-size="jobPageData.pageSize" :current-page="jobPageData.currentPage" :total="jobPageData.total" @current-change="jobCurrentChange" />
          </div>
        </div>
      </div>
    </div>
  </div>


  <el-dialog
    v-model="openBaseInfoFlag"
    title="基础信息"
    class="edit-info-dialog"
    :close-on-click-modal="false"
  >
    <div class="edit-info-dialog-content">
      <el-form :model="showBaseInfo" label-width="auto" :rules="showBaseRules" @submit.native.prevent ref="ShowBaseInfoFormRef" >
        <div class="info-group">
          <div class="info-row">
            <el-form-item class="info-item" label="联系人" prop="name">
              <el-input class="info-item-input" v-model="showBaseInfo.name" />
            </el-form-item>
            <el-form-item class="info-item" label="性别" prop="sex">
              <el-radio-group v-model="showBaseInfo.sex" class="info-item-input">
                <el-radio value="1">男</el-radio>
                <el-radio value="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="微信号" prop="wechat">
              <el-input class="info-item-input" v-model="showBaseInfo.wechat" />
            </el-form-item>
            <el-form-item class="info-item" label="手机号" prop="phone_number">
              <el-input class="info-item-input" v-model="showBaseInfo.phone_number" />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="btn-group">
        <el-button type="primary" @click="savebaseInfoBtn" >保存</el-button>
      </div>
    </div>
  </el-dialog>

  <el-dialog
    v-model="openStaffInfoFlag"
    title="个人履历"
    class="edit-info-dialog"
    :close-on-click-modal="false"
  >
    <div class="edit-info-dialog-content">
      <el-form :model="showStaffInfo" label-width="auto"  :rules="showStaffRules" @submit.native.prevent ref="ShowStaffInfoFormRef" >
        <div class="info-group">
          <div class="info-row">
            <el-form-item class="info-item" label="工作地点" prop="regionModel">
              <el-cascader v-model="showStaffInfo.regionModel" :options="regionTree" :props="{value: 'region_code', label: 'region_name'}" placeholder="选择城市" />
            </el-form-item>
            <el-form-item class="info-item" label="求职状态" prop="fullTimeTypeMode">
              <el-select  v-model="showStaffInfo.fullTimeTypeMode" class="info-item-input" >
                <el-option label="启用-寻找兼职" :value="0"></el-option>
                <el-option label="启用-寻找全职" :value="1"></el-option>
                <el-option label="关闭-暂不考虑" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="学校" prop="schoolTags">
              <el-tag
                v-for="tag in showStaffInfo.schoolTags"
                :key="tag"
                closable
                :disable-transitions="false"
                size="large"
                @close="handleStaffSchoolClose(tag)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                v-show="showStaffCustom.schoolInputTagVisible"
                ref="InputStaffSchoolRef"
                v-model="showStaffCustom.schoolInputValue"
                class="w-20 tag-input"
                maxlength="20"
                @keyup.enter="handleStaffSchoolInputConfirm"
                @blur="handleStaffSchoolInputConfirm"
              />
              <el-button v-show="!showStaffCustom.schoolInputTagVisible" class="keyword-button-new-tag" @click="handleShowInputStaffSchool">
                + 学校
              </el-button>
              <span class="tip-text">学校最多支持20个字</span>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="证书" prop="certificateTags">
              <el-tag
                v-for="tag in showStaffInfo.certificateTags"
                :key="tag"
                closable
                :disable-transitions="false"
                size="large"
                @close="handleStaffCertificateClose(tag)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                v-show="showStaffCustom.certificateInputTagVisible"
                ref="InputStaffCertificateRef"
                v-model="showStaffCustom.certificateInputValue"
                class="w-20 tag-input"
                maxlength="20"
                @keyup.enter="handleStaffCertificateInputConfirm"
                @blur="handleStaffCertificateInputConfirm"
              />
              <el-button v-show="!showStaffCustom.certificateInputTagVisible" class="keyword-button-new-tag" @click="handleShowInputStaffCertificate">
                + 证书
              </el-button>
              <span class="tip-text">证书最多支持20个字</span>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="个人介绍" prop="introduction">
              <el-input class="info-item-input" v-model="showStaffInfo.introduction" type="textarea" :autosize="{ minRows: 4, maxRows: 10 }"  />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="btn-group">
        <el-button type="primary" @click="saveStaffInfoBtn" >保存</el-button>
      </div>
    </div>
  </el-dialog>

  <el-dialog
    v-model="openJobInfoFlag"
    title="发布工作"
    class="edit-info-dialog"
    :close-on-click-modal="false"
  >
    <div class="edit-info-dialog-content">
      <el-form :model="showJobInfo" label-width="auto"  :rules="showJobRules" @submit.native.prevent ref="ShowJobInfoFormRef" >
        <div class="info-group">
          <div class="info-row">
            <el-form-item class="info-item" label="标题" prop="title">
              <el-input class="info-item-input" v-model="showJobInfo.title" />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="工作地点" prop="regionModel">
              <el-cascader v-model="showJobInfo.regionModel" :options="regionTree" :props="{value: 'region_code', label: 'region_name'}" placeholder="选择城市" />
            </el-form-item>
            <el-form-item class="info-item" label="工作类型" prop="sex">
              <el-select  v-model="showJobInfo.full_time_type" class="info-item-input" >
                <el-option label="兼职" :value="0"></el-option>
                <el-option label="全职" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="工作介绍" prop="introduction">
              <el-input class="info-item-input" v-model="showJobInfo.introduction" type="textarea" :autosize="{ minRows: 4, maxRows: 10 }"  />
            </el-form-item>
          </div>
          <div class="info-row" v-if="showJobInfo.full_time_type == 0">
            <el-form-item class="info-item" label="预计入场时间" prop="entry_time">
              <el-date-picker v-model="showJobInfo.entry_time" type="date" placeholder="选择入场时间" format="YYYY-MM-DD" value-format="YYYY-MM-DD"/>
            </el-form-item>
            <el-form-item class="info-item" label="预计结束时间" prop="end_time">
              <el-date-picker v-model="showJobInfo.end_time" type="date" placeholder="选择结束时间" format="YYYY-MM-DD" value-format="YYYY-MM-DD"/>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="薪资情况" prop="salary">
              <div  v-if="showJobInfo.full_time_type == 0">
                日薪：
                <el-input-number class="info-item-input" v-model="showJobInfo.salary"  :min="0" :max="10000"  controls-position="right"/>
                元
              </div>
              <div v-else>
                年薪：
                <el-input-number class="info-item-input" v-model="showJobInfo.salary"  :min="0" :max="100" controls-position="right"/>
                及万元以上
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="btn-group">
        <el-button type="primary" @click="saveJobInfoBtn" >保存</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import { ElMessage } from 'element-plus'
import {dateUtils} from '../utils/dateUtils'
import nodeServer from '@/net/nodeServer'
import nodeServerRelease from '@/net/nodeServerRelease'
import { nextTick } from 'vue'

export default {
  name: 'ReleasePersonalCenter',
  components: {
  },
  data () {
    return {
      dictInfo: {},
      regionTree: [],
      baseInfoLoading: false,
      baseInfo: {},
      staffInfoLoading: false,
      staffInfo: {},
      jobPageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        params: {
          full_time_type: 0
        },
        loading: false,
      },
      // 基础信息
      openBaseInfoFlag: false,
      showBaseInfo: {
        id             : null,
        user_id        : null,
        name           : "昵称",
        phone_number   : "17512081250",
        wechat         : "itsegg_",
        sex            : '1',   //1男, 2女
        examine_status : 0,
        create_time    : dateUtils.getTodayDateTimeString(),
        update_time    : dateUtils.getTodayDateTimeString(),
      },
      showBaseAuditInfo: {},
      showBaseRules: {
        name: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
          { max: 10, message: '最大长度10', trigger: 'blur' },
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        wechat: [
          { required: true, message: '请输入微信号', trigger: 'blur' },
          { max: 20, message: '最大长度20', trigger: 'blur' },
        ],
        phone_number: [
          { pattern : /^1[3-9]\d{9}$|^0\d{2,3}-\d{7,8}$/, message: '请输入正确的手机号', trigger: ['blur'] }
        ],
      },
      // 个人履历
      openStaffInfoFlag: false,
      showStaffInfo: {
        id             : null,
        user_id        : null,
        is_active      : 1,
        school         : "",
        certificate    : "",
        province       : "440000",
        city           : "440100",
        introduction   : "个人介绍",
        examine_status : 0,
        full_time_type : 0,
        create_time    : dateUtils.getTodayDateTimeString(),
        update_time    : dateUtils.getTodayDateTimeString(),
        schoolTags    : [],
        certificateTags  : [],
        regionModel    : [],  // 省、市
        fullTimeTypeMode: 0,  //兼职类型, is_active = 0 => fullTimeTypeMode = 2
      },
      showStaffCustom: {
        schoolInputTagVisible: false,
        schoolInputValue: '',
        certificateInputTagVisible: false,
        certificateInputValue: '',
      },
      showStaffAuditInfo: {},
      showStaffRules: {
        fullTimeTypeMode: [
          { required: true, message: '请选择求职状态', trigger: 'change' }
        ],
        regionModel: [
          {type: 'array',required: true,message: '请选择工作地点',trigger: 'change'},
        ],
        introduction: [
          { required: true, message: '请输入个人介绍', trigger: 'blur' },
          { max: 1000, message: '最大长度1000', trigger: 'blur' },
        ],
      },
      // 工作信息
      openJobInfoFlag: false,
      showJobInfo: {
        id             : null,
        user_id        : null,
        is_active      : 1,
        province       : "",
        city           : "",
        title          : "",
        keyword        : "关键词(逗号,隔开)",
        introduction   : "介绍",
        entry_time     : null,
        end_time       : null,   //预计结束时间
        salary         : "",   //薪资情况
        examine_status : 0,
        is_delete      : 0,
        full_time_type : 0,
        create_time    : dateUtils.getTodayDateTimeString(),
        update_time    : dateUtils.getTodayDateTimeString(),

        regionModel    : [],  // 省、市
      },
      showJobRules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { max: 50, message: '最大长度50', trigger: 'blur' },
        ],
        regionModel: [
          {type: 'array',required: true,message: '请选择工作地点',trigger: 'change'},
        ],
        introduction: [
          { required: true, message: '请输入工作介绍', trigger: 'blur' },
          { max: 1000, message: '最大长度1000', trigger: 'blur' },
        ],
        entry_time: [
          { required: true, message: '请选择项目入场时间', trigger: 'change' },
        ],
        end_time: [
          { required: true, message: '请选择项目结束时间', trigger: 'change' },
        ],
        salary: [
          { required: true, message: '请输入薪资情况', trigger: 'blur' },
          { type: "number", min: 1, message: "薪资情况必须大于0"}
        ],
      },
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
      this.initDictInfo();
      this.initRegionTree();
      this.initBaseInfo();
      this.initStaffInfo();
      this.initJobInfoPage();
    },
    // 判断2个entity字段是否变化
    getEntityIsChangeColumns(entity1, entity2){
      let changeData = {
        columns: [],
        oldData: {}
      }
      for(let key in entity1){
        if(entity2.hasOwnProperty(key) && entity1[key] != entity2[key]){
          changeData.columns.push(key);
          changeData.oldData[key] = entity1[key];

          entity1[key] = entity2[key];
        }
      }
      return changeData;
    },
    checkHasChangeColumn(auditInfo, key){
      if(auditInfo && auditInfo.changeColumns && auditInfo.changeColumns.includes(key)){
        return true;
      }
      return false;
    },
    getUpdateAuditTitleMessage(auditInfo, column){
      let message = `变更${this.getDictNameByDictCode('release_examine_status', auditInfo.examine_status)}，\n`;
      if(auditInfo.examine_status == 2){
        // 审核失败的
        message += `失败原因：${auditInfo.examine_message}，\n`;
      }
      // 旧数据
      message += `旧数据：${auditInfo.changeOldData[column]}\n`;
      return message;
    },
    async initDictInfo(){
      let res = await nodeServer.regionInfo_getDictInfo();
      if(res.success && res.content){
        this.dictInfo = res.content;
      }
    },
    getDictNameByDictCode(dictType, dictCode){
      if(this.dictInfo && this.dictInfo[dictType] && this.dictInfo[dictType][dictCode]){
        return this.dictInfo[dictType][dictCode];
      }
      return '';
    },
    async initRegionTree(){
      let res = await nodeServer.regionInfo_getRegionTree({});
      if(res.success && res.content){
        this.regionTree = res.content;
      }
    },
    async initBaseInfo(){
      // loading效果打开
      this.baseInfoLoading = true;

      let res = await nodeServerRelease.releaseBaseInfo_getOneBySelf({});
      if(res.success && res.content){
        this.baseInfo = res.content;
        // 处理信息
        if(this.examine_status == 2){
          this.examine_message = '\n' + this.examine_message;
        }

        // 获取最后一条审核记录
        let auditInfo = await this.getLastAuditBySelf(this.baseInfo.id, 1);
        if(auditInfo){
          this.showBaseAuditInfo = auditInfo;
          // 处理business_json字符串转换
          this.showBaseAuditInfo.businessEntity = JSON.parse(this.showBaseAuditInfo.business_json);
          // 判断哪些需要显示状态
          if(this.showBaseAuditInfo.examine_type == 2 && (this.showBaseAuditInfo.examine_status == 0 || this.showBaseAuditInfo.examine_status == 2)){
            // 更新类型的，待审核、审核失败的数据，才需要判断
            let changeData = this.getEntityIsChangeColumns(this.baseInfo, this.showBaseAuditInfo.businessEntity);
            if(changeData.columns.length > 0){
              this.showBaseAuditInfo.changeColumns = changeData.columns;
              this.showBaseAuditInfo.changeOldData = changeData.oldData;
            }
          }
        }
      }
      // loading效果关闭
      this.baseInfoLoading = false;
    },
    async initStaffInfo(){
      // loading效果打开
      this.staffInfoLoading = true;
      let res = await nodeServerRelease.releaseStaffInfo_getOneBySelf({});
      if(res.success && res.content){
        this.staffInfo = res.content;
        // 处理信息
        if(this.examine_status == 2){
          this.examine_message = '\n' + this.examine_message;
        }
        // 处理显示的信息
        this.staffInfo.fullTimeTypeMode = this.staffInfo.full_time_type;
        if(this.staffInfo.is_active == 0){
          this.staffInfo.fullTimeTypeMode = 2;
        }

        // 获取最后一条审核记录
        let auditInfo = await this.getLastAuditBySelf(this.staffInfo.id, 2);
        if(auditInfo){
          this.showStaffAuditInfo = auditInfo;
          // 处理business_json字符串转换
          this.showStaffAuditInfo.businessEntity = JSON.parse(this.showStaffAuditInfo.business_json);
          // 判断哪些需要显示状态
          if(this.showStaffAuditInfo.examine_type == 2 && (this.showStaffAuditInfo.examine_status == 0 || this.showStaffAuditInfo.examine_status == 2)){
            // 更新类型的，待审核、审核失败的数据，才需要判断
            let changeData = this.getEntityIsChangeColumns(this.staffInfo, this.showStaffAuditInfo.businessEntity);
            if(changeData.columns.length > 0){
              this.showStaffAuditInfo.changeColumns = changeData.columns;
              this.showStaffAuditInfo.changeOldData = changeData.oldData;
            }
          }
        }
      }
      // loading效果关闭
      this.staffInfoLoading = false;
    },
    async initJobInfoPage(){
      // loading效果打开
      this.jobPageData.loading = true;

      let searchParams = {
        pageNum: this.jobPageData.currentPage,
        pageSize: this.jobPageData.pageSize,
        full_time_type: this.jobPageData.params.full_time_type
      }
      let res = await nodeServerRelease.releaseJobInfo_getPageBySelf(searchParams);
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          // 审核状态
          row.examine_status_enum = this.getDictNameByDictCode('release_examine_status', row.examine_status);
          // 兼职状态
          row.full_time_type_enum = this.getDictNameByDictCode('full_time_type', row.full_time_type);
          // 薪资情况
          if(row.full_time_type == 0){
            row.salary_enum = `日薪${row.salary}元`;
          }else{
            row.salary_enum = `年薪${row.salary}万元及以上`;
          }
          // 行政区划
          this.translateRegionCode(row);
          // 项目时间
          if(row.full_time_type == 0){
            row.entry_time_enum = dateUtils.getDateString(new Date(row.entry_time));
            row.end_time_enum = dateUtils.getDateString(new Date(row.end_time));
          }
        }

        this.jobPageData.rows = res.content.rows;
        this.jobPageData.total = res.content.total;
      }
      // loading效果关闭
      this.jobPageData.loading = false;
    },
    // 处理省份和城市的字段翻译
    translateRegionCode(item){
      if(item && item.province){
        // 遍历获取省份
        for(let regionItem of this.regionTree){
          if(item.province == regionItem.region_code){
            item.province_name = regionItem.region_name;
            // 遍历获取城市
            if(item.city && regionItem.children){
              for(let cityItem of regionItem.children){
                if(item.city == cityItem.region_code) {
                  item.city_name = cityItem.region_name;
                  break;
                }
              }
            }
            break;
          }
        }
        item.region_name = item.province_name;
        if(item.city_name){
          item.region_name += ' - ' + item.city_name;
        }
      }
    },
    jobCurrentChange(currentPage){
      this.jobPageData.currentPage = currentPage;
      this.initJobInfoPage()
    },
    /**
     * 获取最后一条审核记录
     * @param business_id 业务id
     * @param business_type 业务类型, 1: 个人基础信息表, 2: 找工作人员信息表, 3: 工作信息表,
     * @returns {Promise<void>}
     */
    async getLastAuditBySelf(business_id, business_type){
      let params = {
        business_id: business_id,
        business_type: business_type,
      }
      let res = await nodeServerRelease.releaseAuditInfo_getLastAuditBySelf(params);
      if(res.success && res.content){
        return res.content;
      }
    },
    clickBaseShowBtn(){
      this.openBaseInfoFlag = true;
      // 处理数据
      if(this.baseInfo.id){
        this.showBaseInfo = JSON.parse(JSON.stringify(this.baseInfo));
      }else{
        this.showBaseInfo = {
          id             : null,
          user_id        : null,
          name           : "",
          phone_number   : "",
          wechat         : "",
          sex            : '1',   //1男, 2女
          examine_status : 0,
          create_time    : dateUtils.getTodayDateTimeString(),
          update_time    : dateUtils.getTodayDateTimeString(),
        }
      }
    },
    async savebaseInfoBtn(){
      // 保存数据到服务器
      const myComponentInstance = this.$refs.ShowBaseInfoFormRef;
      if (!myComponentInstance) return
      await myComponentInstance.validate((valid, fields) => {
        if (valid) {
          this.saveBaseInfoToService();
        } else {
          ElMessage.error('请先完成信息填写');
        }
      })
    },
    async saveBaseInfoToService(){
      let entityInfo = JSON.parse(JSON.stringify(this.showBaseInfo));
      //  调用接口插入
      let res;
      if(entityInfo.id){
        // 存在id的，更新
        res = await nodeServerRelease.releaseBaseInfo_update(entityInfo);
      }else{
        // 不存在id的，插入
        res = await nodeServerRelease.releaseBaseInfo_create(entityInfo);
      }
      if(res.success){
        // 插入成功， 关闭弹窗，刷新列表
        ElMessage.success('保存成功');
        // 关闭弹窗
        this.openBaseInfoFlag = false;
        await this.initBaseInfo();
      } else {
        if(res.message && res.message.length < 50){
          ElMessage.error(res.message);
        }else{
          ElMessage.error('保存失败，请联系小哥');
        }
      }
    },

    // 打开个人履历详情
    clickStaffShowBtn(){
      // 如果个人基础信息还没有添加的，提示
      if(!this.baseInfo.id){
        ElMessage.info("请先维护基础信息")
        this.clickBaseShowBtn();
        return;
      }

      this.openStaffInfoFlag = true;
      // 处理数据
      if(this.staffInfo.id){
        this.showStaffInfo = JSON.parse(JSON.stringify(this.staffInfo));
        // 处理数据
        // 求职状态
        this.showStaffInfo.fullTimeTypeMode = this.showStaffInfo.full_time_type;
        if(this.showStaffInfo.is_active == 0){
          this.showStaffInfo.fullTimeTypeMode = 2;
        }
        // 工作地点
        this.showStaffInfo.regionModel = [];
        if(this.showStaffInfo.province){
          this.showStaffInfo.regionModel.push(this.showStaffInfo.province);
        }
        if(this.showStaffInfo.city){
          this.showStaffInfo.regionModel.push(this.showStaffInfo.city);
        }
        // 学校、证书
        if(this.showStaffInfo.school){
          this.showStaffInfo.schoolTags = this.showStaffInfo.school.split(',');
        }else{
          this.showStaffInfo.schoolTags = [];
        }
        if(this.showStaffInfo.certificate){
          this.showStaffInfo.certificateTags = this.showStaffInfo.certificate.split(',');
        }else{
          this.showStaffInfo.certificateTags = [];
        }
      }else{
        this.showStaffInfo = {
          id             : null,
          user_id        : null,
          is_active      : 1,
          school         : "",
          certificate    : "",
          province       : "",
          city           : "",
          introduction   : "",
          examine_status : 0,
          full_time_type : 0,
          create_time    : dateUtils.getTodayDateTimeString(),
          update_time    : dateUtils.getTodayDateTimeString(),
          schoolTags    : [],
          certificateTags  : [],
          regionModel    : [],  // 省、市
          fullTimeTypeMode: 0,  //兼职类型, is_active = 0 => fullTimeTypeMode = 2
        }
      }
    },
    // 学校，多个编辑
    handleStaffSchoolClose(tag){
      this.showStaffInfo.schoolTags.splice(this.showStaffInfo.schoolTags.indexOf(tag), 1)
    },
    handleStaffSchoolInputConfirm(){
      if (this.showStaffCustom.schoolInputValue) {
        this.showStaffInfo.schoolTags.push(this.showStaffCustom.schoolInputValue);
      }
      this.showStaffCustom.schoolInputTagVisible = false;
      this.showStaffCustom.schoolInputValue = '';
    },
    handleShowInputStaffSchool(){
      this.showStaffCustom.schoolInputTagVisible = true;
      // 通过this.$refs访问子组件实例
      const myComponentInstance = this.$refs.InputStaffSchoolRef;
      nextTick(() => {
        if(myComponentInstance && myComponentInstance.input){
          myComponentInstance.input.focus();
        }
      })
    },
    // 证书，多个编辑
    handleStaffCertificateClose(tag){
      this.showStaffInfo.certificateTags.splice(this.showStaffInfo.certificateTags.indexOf(tag), 1)
    },
    handleStaffCertificateInputConfirm(){
      if (this.showStaffCustom.certificateInputValue) {
        this.showStaffInfo.certificateTags.push(this.showStaffCustom.certificateInputValue);
      }
      this.showStaffCustom.certificateInputTagVisible = false;
      this.showStaffCustom.certificateInputValue = '';
    },
    handleShowInputStaffCertificate(){
      this.showStaffCustom.certificateInputTagVisible = true;
      // 通过this.$refs访问子组件实例
      const myComponentInstance = this.$refs.InputStaffCertificateRef;
      nextTick(() => {
        if(myComponentInstance && myComponentInstance.input){
          myComponentInstance.input.focus();
        }
      })
    },
    async saveStaffInfoBtn(){
      // 保存数据到服务器
      const myComponentInstance = this.$refs.ShowStaffInfoFormRef;
      if (!myComponentInstance) return
      await myComponentInstance.validate((valid, fields) => {
        if (valid) {
          this.saveStaffInfoToService();
        } else {
          ElMessage.error('请先完成信息填写');
        }
      })
    },
    async saveStaffInfoToService(){
      let entityInfo = JSON.parse(JSON.stringify(this.showStaffInfo));
      // 处理数据
      // 处理全职状态和是否启用
      if(entityInfo.fullTimeTypeMode == 2){
        entityInfo.is_active = 0;
      }else{
        entityInfo.is_active = 1;
        entityInfo.full_time_type = entityInfo.fullTimeTypeMode;
      }
      delete entityInfo.fullTimeTypeMode;
      // 处理区划信息
      if(entityInfo.regionModel && entityInfo.regionModel.length > 0){
        entityInfo.province = entityInfo.regionModel[0];
        entityInfo.city = entityInfo.regionModel[1] ? entityInfo.regionModel[1] : '';
      }
      delete entityInfo.regionModel;
      // 处理学校证书
      if(entityInfo.schoolTags && entityInfo.schoolTags.length > 0){
        entityInfo.school = entityInfo.schoolTags.join(',');
      }else{
        entityInfo.school = '';
      }
      if(entityInfo.certificateTags && entityInfo.certificateTags.length > 0){
        entityInfo.certificate = entityInfo.certificateTags.join(',');
      }else{
        entityInfo.certificate = '';
      }
      delete entityInfo.schoolTags;
      delete entityInfo.certificateTags;

      //  调用接口插入
      let res;
      if(entityInfo.id){
        // 存在id的，更新
        res = await nodeServerRelease.releaseStaffInfo_update(entityInfo);
      }else{
        // 不存在id的，插入
        res = await nodeServerRelease.releaseStaffInfo_create(entityInfo);
      }
      if(res.success){
        // 插入成功， 关闭弹窗，刷新列表
        ElMessage.success('保存成功');
        // 关闭弹窗
        this.openStaffInfoFlag = false;
        await this.initStaffInfo();
      } else {
        if(res.message && res.message.length < 50){
          ElMessage.error(res.message);
        }else{
          ElMessage.error('保存失败，请联系小哥');
        }
      }
    },
    async changeFullTimeType(){
      let updateFullTimeEntity = {
        id: this.staffInfo.id,
        is_active: 0,
        full_time_type: 0,
      }
      let fullTimeTypeMode = this.staffInfo.fullTimeTypeMode;

      if(fullTimeTypeMode == 2){
        updateFullTimeEntity.is_active = 0;
      }else{
        updateFullTimeEntity.is_active = 1;
        updateFullTimeEntity.full_time_type = fullTimeTypeMode;
      }

      await nodeServerRelease.releaseStaffInfo_updateFullTime(updateFullTimeEntity);

    },

    // 发布工作维护
    clickJobShowBtn(jobItem){
      // 如果个人基础信息还没有添加的，提示
      if(!this.baseInfo.id){
        ElMessage.info("请先维护基础信息")
        this.clickBaseShowBtn();
        return;
      }

      this.openJobInfoFlag = true;
      // 处理数据
      if(jobItem && jobItem.id){
        this.showJobInfo = JSON.parse(JSON.stringify(jobItem));
        // 处理数据
        // 工作地点
        this.showJobInfo.regionModel = [];
        if(this.showJobInfo.province){
          this.showJobInfo.regionModel.push(this.showJobInfo.province);
        }
        if(this.showJobInfo.city){
          this.showJobInfo.regionModel.push(this.showJobInfo.city);
        }
      }else{
        // 根据兼职类型，设置查询
        this.showJobInfo = {
          id             : null,
          user_id        : null,
          is_active      : 1,
          province       : "",
          city           : "",
          title          : "",
          keyword        : "",
          introduction   : "",
          entry_time     : null,
          end_time       : null,   //预计结束时间
          salary         : "",   //薪资情况
          examine_status : 0,
          is_delete      : 0,
          full_time_type : this.jobPageData.params.full_time_type,
          create_time    : dateUtils.getTodayDateTimeString(),
          update_time    : dateUtils.getTodayDateTimeString(),

          regionModel    : [],  // 省、市
        }
      }
    },

    async saveJobInfoBtn(){
      // 保存数据到服务器
      const myComponentInstance = this.$refs.ShowJobInfoFormRef;
      if (!myComponentInstance) return
      await myComponentInstance.validate((valid, fields) => {
        if (valid) {
          this.saveJobInfoToService();
        } else {
          ElMessage.error('请先完成信息填写');
        }
      })
    },
    async saveJobInfoToService(){
      let entityInfo = JSON.parse(JSON.stringify(this.showJobInfo));
      // 处理数据
      // 处理区划信息
      if(entityInfo.regionModel && entityInfo.regionModel.length > 0){
        entityInfo.province = entityInfo.regionModel[0];
        entityInfo.city = entityInfo.regionModel[1] ? entityInfo.regionModel[1] : '';
      }
      delete entityInfo.regionModel;

      //  调用接口插入
      let res;
      if(entityInfo.id){
        // 存在id的，更新
        res = await nodeServerRelease.releaseJobInfo_update(entityInfo);
      }else{
        // 不存在id的，插入
        res = await nodeServerRelease.releaseJobInfo_create(entityInfo);
      }
      if(res.success){
        // 插入成功， 关闭弹窗，刷新列表
        ElMessage.success('保存成功');
        // 关闭弹窗
        this.openJobInfoFlag = false;
        // 根据兼职类型，设置查询
        this.jobPageData.params.full_time_type = entityInfo.full_time_type;
        await this.initJobInfoPage();
      } else {
        if(res.message && res.message.length < 50){
          ElMessage.error(res.message);
        }else{
          ElMessage.error('保存失败，请联系小哥');
        }
      }
    },
    handleClickJobHeader(full_time_type){
      this.jobPageData.params.full_time_type = full_time_type;
      this.initJobInfoPage();
    }
  }
}
</script>
<style lang="scss" scoped>
.release-personal-center{
  width: 100%;
  font-size: 14px;
  .content {
    min-height: 800px;
    margin: 24px 240px 48px 240px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 水平偏移 垂直偏移 模糊半径 颜色 */
    border-radius: 20px;
    background: linear-gradient(to bottom, #f0f0f0, #fff 90%);
    .examine-status-icon{
      margin-left: 8px;
      cursor: pointer;
      font-size: 14px;
      &.examine-status-pass{
        color: #00c48f;
      }
      &.examine-status-todo{
        color: #ff9f00;
      }
      &.examine-status-no-pass{
        color: #f44336;
      }
    }
    .content-custom-btn{
      height: 36px;
      width: 100px;
      position: absolute;
      top: 20px;
      right: 0;
      line-height: 36px;
      text-align: center;
      border-radius: 15px 0 0 15px;
      border: #d9d9d9 solid 2px;
      border-right: none;
      display: flex;
      align-items: center; /* 垂直居中对齐 */
      justify-content:center;
      cursor: pointer;
      .el-icon{
        margin-left: 2px;
        font-size: 11px;
        color: #bfbfbf;
      }
      &:hover,&:active{
        background: rgba(121, 187, 255, 0.1);
        border: #79bbff solid 2px;
        border-right: none;
        .el-icon{
          color: #79bbff;
        }
      }
    }
    .content-custom-examine-status{
      position: absolute;
      top: 72px;
      right: 20px;
      display: flex;
      align-items: center; /* 垂直居中对齐 */
      font-size: 14px;
      color: #8c8c8c;
      .examine-status-icon{
        font-size: 16px;
        margin-right: 4px;
      }
    }
    .content-nodata{
      display: flex;
      align-items: center; /* 垂直居中对齐 */
      justify-content:center;
      width: 100%;
      height: 100%;
      .content-nodata-btn{
        height: 48px;
        width: 160px;
        line-height: 48px;
        text-align: center;
        border-radius: 4px;
        border: #d9d9d9 solid 2px;
        display: flex;
        align-items: center; /* 垂直居中对齐 */
        justify-content:center;
        cursor: pointer;
        font-size: 16px;
        .el-icon{
          margin-right: 6px;
          font-size: 20px;
          color: #bfbfbf;
        }
        &:hover,&:active{
          background: rgba(121, 187, 255, 0.1);
          border: #79bbff solid 2px;
          .el-icon{
            color: #79bbff;
          }
        }
      }
    }
    .content-top{
      display: flex;
      height: 200px;
      padding: 24px 24px;
      .content-base-info{
        height: 100%;
        width: 480px;
        border-radius: 12px;
        background: #fff;
        margin-right: 24px;
        position: relative;
        display: flex;
        .content-base-info-left{
          width: 130px;
          .content-base-info-left-image{
            height: 60px;
            width: 60px;
            line-height: 60px;
            margin: 24px 40px;
            position: relative;
            .content-base-info-left-image-info{
              height: 100%;
              width: 100%;
              text-align: center;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 水平偏移 垂直偏移 模糊半径 颜色 */
              border-radius: 50%;
              i.iconfont{
                font-size: 36px;
              }
            }
            .content-base-info-left-image-sex{
              position: absolute;
              right: -4px;
              top: -4px;
              height: 18px;
              width: 18px;
              line-height: 18px;
              text-align: center;
              background: rgb(90,217,255);
              border-radius: 50% 50% 50% 2px;
              i.iconfont{
                font-size: 10px;
                color: #fff;
              }

              &.sex_nv{
                background: rgb(255,117,110);
              }
            }
          }
        }
        .content-base-info-right{
          flex: 1;
          margin: 20px 16px;
          .content-base-info-right-item{
            display: flex;
            margin-bottom: 16px;
            font-size: 16px;
            .right-item-name{
              line-height: 40px;
              font-size: 24px;
            }
            .right-item-phone-number{
              color: #bfbfbf;
              i.iconfont{
                margin-right: 8px;
                color: rgb(255,144,0);
              }
            }
            .right-item-wechat{
              color: #bfbfbf;
              i.iconfont{
                margin-right: 8px;
                font-size: 18px;
                color: rgb(22,212,107);
              }
            }
          }
        }
      }
      .content-staff-info {
        flex: 1;
        width: calc(100% - 504px);
        height: 100%;
        border-radius: 12px;
        background: #fff;
        position: relative;

        .content-staff-info-top {
          display: flex;
          margin: 16px 32px 0 32px;

          .info-top-item {

            &.item-letf{
              width: 240px;
            }
            &.item-right{
              width: calc(100% - 360px);
            }
            .info-top-item-row{
              line-height: 40px;
              display: flex;
              .el-select{
                width: 160px;
                margin-top: 6px;
              }
              i.iconfont{
                color: #bfbfbf;
                margin-right: 12px;
              }
              .item-row-span{
                white-space: nowrap; /* 确保文本不换行 */
                overflow: hidden; /* 隐藏溢出的内容 */
                text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
              }
            }
          }
        }

        .content-staff-info-bottom {
          margin: 4px 32px 16px 32px;
          line-height: 40px;
          display: flex;
          i.iconfont{
            color: #bfbfbf;
            margin-right: 12px;
          }
          .item-row-span{
            white-space: nowrap; /* 确保文本不换行 */
            overflow: hidden; /* 隐藏溢出的内容 */
            text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
          }
        }
      }
    }
    .content-bottom{
      padding: 0px 24px;

      .content-job-info{
        border-radius: 12px;
        background: #fff;
        min-height: 480px;
        position: relative;
        .content-job-header{
          height: 60px;
          padding-bottom: 4px;;
          display: flex;
          align-items: end; /* 垂直居中 */
          .header-item {
            margin-left: 32px;
            text-align: center;
            font-size: 16px;
            cursor: pointer;
            height: 36px;
            line-height: 36px;
            width: 120px;
            border-radius: 8px;
            color: #262626;
            background: linear-gradient(to bottom, #f5f5f5, #ffffff);
            &:hover {
              box-shadow: 0 0 8px rgba(140, 140, 140, 0.4); /* 水平偏移 垂直偏移 模糊半径 颜色 */
            }
            &.active {
              background: linear-gradient(to bottom, #f0f0f0, #fafafa);
              box-shadow: 0 0 8px rgba(140, 140, 140, 0.8); /* 水平偏移 垂直偏移 模糊半径 颜色 */
            }
          }
        }
        .content-job-list{
          min-height: 400px;
          display: flex;
          flex-wrap: wrap;
          padding: 16px;
          .content-nodata{
            height: 400px;
            border-radius: 8px;
            margin: 12px 20px 12px 16px;
            box-shadow: 0 0 8px rgba(140, 140, 140, 0.4); /* 水平偏移 垂直偏移 模糊半径 颜色 */
          }
          .content-job-item{
            width: calc(50% - 40px);
            height: 160px;
            border-radius: 16px;
            background: linear-gradient(to bottom, #f5f5f5, #ffffff);
            margin: 12px 20px 12px 16px;
            padding-top: 12px;
            cursor: pointer;
            position: relative;
            box-shadow: 0 0 8px rgba(140, 140, 140, 0.4); /* 水平偏移 垂直偏移 模糊半径 颜色 */
            &:hover{
              border: 2px solid #e9e9e9;
              box-shadow: 0 0 8px rgba(140, 140, 140, 0.6); /* 水平偏移 垂直偏移 模糊半径 颜色 */
            }
            .content-custom-examine-status{
              top: 20px;
            }
            .content-job-item-title{
              height: 36px;
              line-height: 36px;
              width: calc(100% - 160px);
              font-size: 18px;
              font-weight: bold;
              margin: 0 24px;
              white-space: nowrap; /* 确保文本不换行 */
              overflow: hidden; /* 隐藏溢出的内容 */
              text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
            }
            .content-job-item-row{
              height: 28px;
              line-height: 28px;
              font-size: 15px;
              margin: 0 24px;
              white-space: nowrap; /* 确保文本不换行 */
              overflow: hidden; /* 隐藏溢出的内容 */
              text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */

              i.iconfont{
                font-size: 16px;
                color: #bfbfbf;
                margin-right: 6px;
              }
              span{
                margin-right: 6px;
              }
            }
            .content-job-item-foot{
              position: absolute;
              bottom: 0px;
              width: 100%;
              height: 36px;
              background: linear-gradient(to bottom, #f0f0f0, #fafafa);
              display: flex;
              padding: 0 24px;
              line-height: 36px;
              border-radius: 0 0 12px 12px;
              font-size: 15px;
              i.iconfont{
                font-size: 16px;
                color: #bfbfbf;
                margin-right: 6px;
              }
              .content-job-item-foot-name{

              }
              .content-job-item-foot-address{
                flex: 1;
                text-align: right;
                margin-right: 12px;
              }
            }
          }
        }
        .content-job-pagination{
          display: flex;
          justify-content:center;
          height: 60px;
          padding-bottom: 20px;

          .el-pagination{
            margin-right: 32px;
          }
          .total{
            line-height: 40px;
            font-size: 14px;
            margin-right: 24px;
          }
        }
      }
    }

  }
}

//弹窗
.edit-info-dialog-content{
  padding: 0 20px 24px 20px;
  .info-group{
    .info-group-title{
      height: 24px;
      line-height: 22px;
      font-size: 14px;
      border-bottom: 1px solid #8c8c8c;
      margin-bottom: 16px;
    }
    .info-row{
      display: flex;
      .info-item{
        flex: 1;
        margin-right: 32px;
        .tag-input, .keyword-button-new-tag{
          width: 160px !important;
        }
        .el-tag{
          margin-right: 8px;
          margin-bottom: 4px;
        }
        .tip-text{
          font-size: 12px;
          margin-left: 12px;
          color: #8c8c8c;
        }
      }
    }
  }
  .btn-group{
    text-align: center;
  }
}
</style>
<style lang="scss">
.edit-info-dialog{
  width: 800px !important;
  .el-dialog__body{
    padding: 0;
  }
}
</style>
