
<template>
  <div class="manage-interact-audit-info" >
    <div class="table-header">
      <div class="query-item">
        <span class="query-item-label">用户id: </span>
        <el-input class="query-item-input" v-model="queryParams.user_id" />
      </div>
      <div class="query-item">
        <span class="query-item-label">业务类型: </span>
        <el-select  v-model="queryParams.business_type" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option label="问题信息" :value="1"></el-option>
          <el-option label="回答信息" :value="2"></el-option>
        </el-select>
      </div>
      <div class="query-item">
        <span class="query-item-label">业务操作类型: </span>
        <el-select  v-model="queryParams.examine_type" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option label="新增数据" :value="1"></el-option>
          <el-option label="修改数据" :value="2"></el-option>
        </el-select>
      </div>
      <div class="query-item">
        <span class="query-item-label">业务id: </span>
        <el-input class="query-item-input" v-model="queryParams.business_id" />
      </div>
      <div class="query-item">
        <span class="query-item-label">审核状态: </span>
        <el-select  v-model="queryParams.examine_status" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="审核通过" :value="1"></el-option>
          <el-option label="审核不通过" :value="2"></el-option>
          <el-option label="取消审核" :value="3"></el-option>
        </el-select>
      </div>
      <div class="btn-group">
        <el-button type="primary" @click="searchBtn">查询</el-button>
        <el-button @click="resetBtn">重置</el-button>
      </div>
    </div>
    <div class="table-content custom-overflow">
      <el-table :data="pageData.rows" stripe style="width: 100%" show-overflow-tooltip >
        <el-table-column prop="id" label="id" width="60" />
        <el-table-column prop="user_id" label="用户id" width="80" />
        <el-table-column prop="business_type_enum" label="业务类型"  width="120"  />
        <el-table-column prop="examine_type_enum" label="业务操作类型" width="120" />
        <el-table-column prop="business_id" label="业务id" width="80"  />
        <el-table-column label="审核状态"  width="120">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span :class="{'success-row': scope.row.examine_status == 1, 'warning-row': scope.row.examine_status == 2, 'cancel-row': scope.row.examine_status == 3}">
                {{ scope.row.examine_status_enum }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="examine_message" label="审核意见" />
        <el-table-column prop="examine_user_name" label="审核人"  width="80" />
        <el-table-column prop="create_time" label="创建时间" />
        <el-table-column prop="examine_time" label="审核时间" />
        <el-table-column label="操作">
          <template #default="scope" >
            <el-button size="small" type="primary" @click="showAuditDialogInfo(scope.$index, scope.row)" v-if="scope.row && scope.row.examine_status == 0">
              审核
            </el-button>
            <el-button size="small" @click="showAuditDialogInfo(scope.$index, scope.row, true)" v-else>
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="total">记录数: {{pageData.total}}条</div>
        <el-pagination background layout="prev, pager, next" :page-size="pageData.pageSize" :current-page="pageData.currentPage" :total="pageData.total" @current-change="currentChange" />
      </div>
    </div>
  </div>

  <el-dialog
    v-model="openAuditDialogFlag"
    title="审核信息"
    class="edit-info-dialog"
    :close-on-click-modal="false"
  >
    <div class="edit-info-dialog-content">
      <el-form :model="auditDialogInfo" label-width="auto" :rules="auditRules" @submit.native.prevent ref="auditInfoFormRef" >
        <!--        问题-->
        <div class="info-group">
          <div class="info-group-title">问题信息</div>
          <div class="info-row">
            <el-form-item class="info-item" label="标题" prop="title">
              <el-input class="info-item-input" v-model="auditDialogInfo.businessEntity.title" disabled />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="内容" prop="content">
              <div class="info-item-html custom-overflow custom-editor" v-html="auditDialogInfo.businessEntity.content"></div>
            </el-form-item>
          </div>
          <div class="info-row" v-if="auditDialogInfo.business_type === 1 && !this.auditDialogOnlyShow">
            <el-form-item class="info-item" label="问题类型" prop="interact_type_list">
              <el-cascader v-model="auditDialogInfo.interact_type_list" :options="interactTypeTree"
                           :props="{ checkStrictly: false, value: 'name', label: 'name', multiple: true}"
                           placeholder="请选择问题类型(可多选)" clearable />
            </el-form-item>
          </div>
          <div class="info-row" v-else>
            <el-form-item class="info-item" label="问题类型" prop="interact_type">
              <el-input class="info-item-input" v-model="auditDialogInfo.businessEntity.interact_type" disabled />
            </el-form-item>
          </div>
        </div>
        <!--        回答信息-->
        <div class="info-group"  v-if="auditDialogInfo.business_type === 2">
          <div class="info-group-title">回答信息</div>
          <div class="info-row">
            <el-form-item class="info-item" label="回答内容" prop="content">
              <div class="info-item-html custom-overflow custom-editor" v-html="auditDialogInfo.answerEntity.content"></div>
            </el-form-item>
          </div>
        </div>
        <!--        审核信息-->
        <div class="info-group">
          <div class="info-group-title">审核信息</div>
          <div class="info-row">
            <el-form-item class="info-item" label="审核状态" prop="examine_status">
              <el-select  v-model="auditDialogInfo.examine_status" class="info-item-input" :disabled="this.auditDialogOnlyShow">
                <el-option label="待审核" :value="0" disabled></el-option>
                <el-option label="审核通过" :value="1"></el-option>
                <el-option label="审核不通过" :value="2"></el-option>
                <el-option label="取消审核" :value="3" disabled></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="审核意见" prop="examine_message">
              <el-input class="info-item-input" v-model="auditDialogInfo.examine_message" type="textarea" :autosize="{ minRows: 4, maxRows: 10 }" :disabled="this.auditDialogOnlyShow" />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="审核人" prop="examine_user_name">
              <el-input class="info-item-input" v-model="auditDialogInfo.examine_user_name" disabled />
            </el-form-item>
            <el-form-item class="info-item" label="审核时间" prop="examine_time">
              <el-input class="info-item-input" v-model="auditDialogInfo.examine_time" disabled />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="btn-group">
        <el-button type="primary" @click="saveAuditInfoBtn" >保存</el-button>
      </div>
    </div>
  </el-dialog>

</template>

<script>

import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'
import nodeServerRelease from '@/net/nodeServerRelease'
import { ElMessage } from 'element-plus'
import defaultConfig from '@/config/defaultConfig'
import nodeServerInteract from "@/net/nodeServerInteract";

export default {
  name: 'ManageInteractAuditInfo',
  components: {

  },
  data () {
    return {
      dictInfo: {},
      interactTypeTree: [],

      pageNum: 1, pageSize: 10,

      queryParams: {
        user_id: '',
        business_type: '',
        examine_type: '',
        business_id: '',
        examine_status: '',
      },

      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },

      auditDialogInfo: {},
      openAuditDialogFlag: false,
      auditDialogOnlyShow: false,
      auditRules: {
        interact_type_list: [
          {type: 'array',required: true,message: '请选择问题类型',trigger: 'change'},
        ],
        examine_status: [
          { required: true, message: '请选择审核状态', trigger: 'change' }
        ],
        examine_message: [
          { required: true, message: '请输入审核意见', trigger: 'blur' },
          { max: 100, message: '最大长度100', trigger: 'blur' },
        ],
      },
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    async init () {
      //  初始化行政区划
      await this.initDictInfo();
      await this.initTypeTree();

      await this.searchBtn();
    },
    resetBtn(){
      this.queryParams =  {
        user_id: '',
        business_type: '',
        examine_type: '',
        business_id: '',
        examine_status: '',
      }

    },
    async initDictInfo(){
      let res = await nodeServer.regionInfo_getDictInfo();
      if(res.success && res.content){
        this.dictInfo = res.content;
      }
    },
    async initTypeTree(){
      let res = await nodeServerInteract.interactTypeInfo_getTypeTree({});
      if(res.success && res.content){
        this.interactTypeTree = res.content;
      }
    },
    // 查询
    async searchBtn(){
      let searchParams = {
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        user_id: this.queryParams.user_id,
        business_type: this.queryParams.business_type,
        examine_type: this.queryParams.examine_type,
        business_id: this.queryParams.business_id,
        examine_status: this.queryParams.examine_status,
      }
      let res = await nodeServerInteract.interactAuditInfo_getPage(searchParams);
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          // 审核状态
          row.examine_status_enum = this.getDictNameByDictCode('interact_examine_status', row.examine_status);
          // 业务类型
          row.business_type_enum = this.getDictNameByDictCode('interact_business_type', row.business_type);
          // 业务操作类型
          row.examine_type_enum = this.getDictNameByDictCode('interact_examine_type', row.examine_type);
        }

        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.searchBtn()
    },
    getDictNameByDictCode(dictType, dictCode){
      if(this.dictInfo && this.dictInfo[dictType] && this.dictInfo[dictType][dictCode]){
        return this.dictInfo[dictType][dictCode];
      }
      return '';
    },
    async showAuditDialogInfo(index, row, onlyShow){
      this.auditDialogOnlyShow = onlyShow;
      this.auditDialogInfo = JSON.parse(JSON.stringify(row));
      if(!onlyShow){
        // 如果是需要审核的，初始化审核内容(默认通过)
        this.auditDialogInfo.examine_status = 1;
        this.auditDialogInfo.examine_message = '';
        this.auditDialogInfo.interact_type_list = [];
      }
      // 处理business_json字符串转换
      this.auditDialogInfo.businessEntity = JSON.parse(this.auditDialogInfo.business_json);
      // 处理 回答信息 时， 要显示问题的信息
      if(this.auditDialogInfo.business_type == 2) {
        this.auditDialogInfo.answerEntity = JSON.parse(this.auditDialogInfo.business_json);
        this.auditDialogInfo.businessEntity = {};
        let res = await nodeServerInteract.interactQuestionInfo_getOneById({
          id: this.auditDialogInfo.answerEntity.question_id,
        })
        if(res.success && res.content){
          this.auditDialogInfo.businessEntity = res.content;
        }
      }
      console.log(this.auditDialogInfo);
      // 打开审核弹窗
      this.openAuditDialogFlag = true;
    },
    // 保存审核状态
    async saveAuditInfoBtn(){
      // 保存数据到服务器
      const myComponentInstance = this.$refs.auditInfoFormRef;
      if (!myComponentInstance) return
      await myComponentInstance.validate((valid, fields) => {
        if (valid) {
          this.saveAuditInfoToService();
        } else {
          ElMessage.error('请先完成信息填写');
        }
      })
    },
    async saveAuditInfoToService(){
      let entityInfo = JSON.parse(JSON.stringify(this.auditDialogInfo));
      console.log(entityInfo)
      let interact_type = '';
      console.log(entityInfo.interact_type_list);
      if(entityInfo.interact_type_list && entityInfo.interact_type_list.length > 0){
        interact_type = this.handleInteractType(entityInfo.interact_type_list);
      }
      console.log(interact_type);
      let res = await nodeServerInteract.interactAuditInfo_auditOneById({
        id: entityInfo.id,
        examine_status: entityInfo.examine_status,
        examine_message: entityInfo.examine_message,
        interact_type: interact_type,
      });
      if(res.success){
        // 插入成功， 关闭弹窗，刷新列表
        ElMessage.success('审核成功');
        // 关闭弹窗
        this.openAuditDialogFlag = false;
        await this.searchBtn();
      } else {
        ElMessage.error('审核失败，' + res.message);
      }
    },
    handleInteractType(list){
      let resultList = [];
      for(let list1 of list){
        resultList.push(list1.join('-'));
      }
      let result = resultList.join(',');
      return result;
    },
  }
}
</script>
<style lang="scss" scoped>
.manage-interact-audit-info{
  width: 100%;
  height: 100%;
  .table-header{
    height: 120px;
    line-height: 60px;
    display: flex;
    flex-wrap: wrap;
    .query-item{
      margin-left: 64px;
      .query-item-label{
        text-align: right;
      }
      .query-item-input{
        margin-left: 16px;
        width: 120px;
      }
    }
    .btn-group{
      margin-left: 32px;
      .el-button{
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
  .table-content{
    height: calc(100% - 120px);
    overflow-y: auto;
    .pagination{
      margin-top: 1rem;
      position: relative;
      float: right;
      display: flex;
      .el-pagination{
        margin-right: 32px;
      }
      .total{
        line-height: 40px;
        font-size: 14px;
        margin-right: 24px;
      }
    }
    .success-row{
      color: #00c48f;
    }
    .warning-row{
      color: #f44336;
    }
    .cancel-row{
      color: #ff9f00;
    }
  }
}

//弹窗
.edit-info-dialog-content{
  padding: 0 20px 24px 20px;
  .info-group{
    .info-group-title{
      height: 24px;
      line-height: 22px;
      font-size: 14px;
      border-bottom: 1px solid #8c8c8c;
      margin-bottom: 16px;
    }
    .info-row{
      display: flex;
      .info-item{
        flex: 1;
        margin-right: 32px;
        .tag-input, .keyword-button-new-tag{
          width: 160px !important;
        }
        .el-tag{
          margin-right: 8px;
          margin-bottom: 4px;
        }
        .tip-text{
          font-size: 12px;
          margin-left: 12px;
          color: #8c8c8c;
        }
        .info-item-images{
          display: flex;
          .el-image{
            width: 160px;
            height: 120px;
            margin-right: 24px;
            border-radius: 4px;
          }
        }
        .info-item-html{
          overflow-y: auto;
          overflow-x: auto;
          width: calc(100%);
          background: #f5f7fa;
          color: #a8abb2;
          border-radius: 6px;
          border: solid 1px #e4e7ed;
          padding: 0 8px;
        }
      }
    }
  }
  .btn-group{
    text-align: center;
  }
}
</style>
<style lang="scss">
.edit-info-dialog{
  width: 800px !important;
  .el-dialog__body{
    padding: 0;
  }
  .el-cascader{
    .el-input{
      min-height: 32px;
    }
  }
}

</style>
