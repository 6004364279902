import axiosWraper from './axiosWraper'

const proxyUrl = '/node_server'

// 问答模块
const nodeServerInteract = {

  //
  /**
   * 获取问题类型（树形结构）
   * @param params *{
   * }
   */
  interactTypeInfo_getTypeTree: async params => {
    const url = `${proxyUrl}/interactTypeInfo/getTypeTree`
    return axiosWraper.getData(url, params)
  },

  /**
   * 获取问题信息数据(用户列表，只看已审核通过的内容)
   * @param params *
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      user_id: user_id,
   *      search: 搜索,
   *      interact_type: 问答类型（中文）,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      is_delete: 逻辑删除,
   * }
   */
  interactQuestionInfo_getPage: async params => {
    const url = `${proxyUrl}/interactQuestionInfo/getPage`
    return axiosWraper.getData(url, params)
  },
  /**
   * 获取问题信息数据(自己列表，只看自己发布内容)
   * @param params *
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      user_id: user_id,
   *      search: 搜索,
   *      interact_type: 问答类型（中文）,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      is_delete: 逻辑删除,
   * }
   */
  interactQuestionInfo_getPageBySelf: async params => {
    const url = `${proxyUrl}/interactQuestionInfo/getPageBySelf`
    return axiosWraper.getData(url, params)
  },
  /**
   * 获取问题信息数据(管理员列表)
   * @param params *
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      user_id: user_id,
   *      search: 搜索,
   *      interact_type: 问答类型（中文）,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      is_delete: 逻辑删除,
   * }
   */
  interactQuestionInfo_getPageByAdmin: async params => {
    const url = `${proxyUrl}/interactQuestionInfo/getPageByAdmin`
    return axiosWraper.getData(url, params)
  },
  /**
   * 创建问题信息
   * @param params *
   * {
   *    title: 标题,
   *    content: 内容,
   *    interact_type: 问题类型, 逗号隔开,
   * ｝
   */
  interactQuestionInfo_create: async params => {
    const url = `${proxyUrl}/interactQuestionInfo/create`
    return axiosWraper.post(url, params)
  },
  /**
   * 删除问题信息
   * @param params *
   * {
   *    id: 111222,
   * ｝
   */
  interactQuestionInfo_deleteById: async params => {
    const url = `${proxyUrl}/interactQuestionInfo/deleteById`
    return axiosWraper.post(url, params)
  },
  /**
   * 问题信息 - 置顶
   * @param params *
   * {
   *    "id": 11212,
   *    "is_top_up": 1,  //是否置顶
   *    "top_up_level": 1,  //置顶级别 1 - 10
   * ｝
   */
  interactQuestionInfo_updateIsTopUpById: async params => {
    const url = `${proxyUrl}/interactQuestionInfo/updateIsTopUpById`
    return axiosWraper.post(url, params)
  },
  /**
   * 问题信息 - 阅读量+1
   * @param params *
   * {
   *    id: 111222,
   * ｝
   */
  interactQuestionInfo_addReadingQuantityById: async params => {
    const url = `${proxyUrl}/interactQuestionInfo/addReadingQuantityById`
    return axiosWraper.post(url, params)
  },
  /**
   * 问题信息 - 通过id获取
   * @param params *
   * {
   *    id: 111222,
   * ｝
   */
  interactQuestionInfo_getOneById: async params => {
    const url = `${proxyUrl}/interactQuestionInfo/getOneById`
    return axiosWraper.getData(url, params)
  },


  /**
   * 问题信息 - 获取文章（通过用户id）
   * @param params *
   * {
   * ｝
   */
  interactQuestionInfo_getOneArticle0ByUser: async params => {
    const url = `${proxyUrl}/interactQuestionInfo/getOneArticle0ByUser`
    return axiosWraper.getData(url, params)
  },

  /**
   * 问题信息 - 创建或者更新文章
   * @param params *
   * {
   *    id: id,
   *    title: 标题,
   *    content: 内容,
   *    interact_type: 问题类型, 逗号隔开,
   *    examine_status: 0: 未审核(暂存), 1: 已审核(提交)
   * ｝
   */
  interactQuestionInfo_createOrUpdateArticle: async params => {
    const url = `${proxyUrl}/interactQuestionInfo/createOrUpdateArticle`
    return axiosWraper.post(url, params)
  },

  /**
   * 获取回答信息数据(用户列表，只看已审核通过的内容)
   * @param params *
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      user_id: user_id,
   *      question_id: 问题id,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      is_delete: 逻辑删除,
   * }
   */
  interactAnswerInfo_getPage: async params => {
    const url = `${proxyUrl}/interactAnswerInfo/getPage`
    return axiosWraper.getData(url, params)
  },
  /**
   * 获取回答信息数据(自己列表，只看自己内容)
   * @param params *
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      user_id: user_id,
   *      question_id: 问题id,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      is_delete: 逻辑删除,
   * }
   */
  interactAnswerInfo_getPageBySelf: async params => {
    const url = `${proxyUrl}/interactAnswerInfo/getPageBySelf`
    return axiosWraper.getData(url, params)
  },
  /**
   * 获取回答信息数据(管理员列表)
   * @param params *
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      user_id: user_id,
   *      question_id: 问题id,
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      is_delete: 逻辑删除,
   * }
   */
  interactAnswerInfo_getPageByAdmin: async params => {
    const url = `${proxyUrl}/interactAnswerInfo/getPageByAdmin`
    return axiosWraper.getData(url, params)
  },
  /**
   * 创建回答信息
   * @param params *
   * {
   *    parent_id: 父节点id,
   *    question_id: 问题id,
   *    content: 内容,
   * ｝
   */
  interactAnswerInfo_create: async params => {
    const url = `${proxyUrl}/interactAnswerInfo/create`
    return axiosWraper.post(url, params)
  },
  /**
   * 创建回答信息
   * @param params *
   * {
   *    id: 12121,
   * ｝
   */
  interactAnswerInfo_deleteById: async params => {
    const url = `${proxyUrl}/interactAnswerInfo/deleteById`
    return axiosWraper.post(url, params)
  },


  /**
   * 获取审核信息数据
   * @param params *
   * {
   *      pageNum: 1, pageSize: 10,
   *      id: ID,
   *      business_id: 业务表id,
   *      user_id: 申请人id,
   *      examine_user_id: 审批人id,
   *      business_type: 业务类型, 1: 个人基础信息表, 2: 找工作人员信息表, 3: 工作信息表,
   *      examine_type: 审核类型, 1: 新增数据, 2: 修改数据
   *      examine_status: 审核状态, 0: 待审核, 1: 审核通过, 2: 审核不通过, 3: 取消审核,
   *      orderby_examine_time: 为true是按审核时间排序,
   * }
   */
  interactAuditInfo_getPage: async params => {
    const url = `${proxyUrl}/interactAuditInfo/getPage`
    return axiosWraper.getData(url, params)
  },
  /**
   * 根据id审核记录
   * @param params *
   * {
   *      id: ID,
   *      examine_status: 1通过/2不通过,
   *      examine_message: "审核信息",
   *      interact_type: "问答类型（中文）"  // 如果是问题的话，需要配置问答类型
   * }
   */
  interactAuditInfo_auditOneById: async params => {
    const url = `${proxyUrl}/interactAuditInfo/auditOneById`
    return axiosWraper.post(url, params)
  },

  /**
   * 投票 - 根据id进行投票
   * @param params *
   * {
   *      business_type: '业务类型, 1: 问题表, 2: 回答表',
   *      business_id: '业务id',
   *      vote_type: '投票类型, 1: 支持, 2: 反对'
   * ｝
   */
  interactVoteInfo_voteById: async params => {
    const url = `${proxyUrl}/interactVoteInfo/voteById`
    return axiosWraper.post(url, params)
  },
  /**
   * 投票 - 根据id取消投票
   * @param params *
   * {
   *      business_type: '业务类型, 1: 问题表, 2: 回答表',
   *      business_id: '业务id',
   * ｝
   */
  interactVoteInfo_cancelVoteById: async params => {
    const url = `${proxyUrl}/interactVoteInfo/cancelVoteById`
    return axiosWraper.post(url, params)
  },

}

export default nodeServerInteract
