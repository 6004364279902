<template>
  <div class="quill-editor-info">
    <div class="disabled-box" v-if="editorDisabled">请先登录</div>
    <quill-editor
      v-model="editorOptions.content"
      ref="myQuillEditor"
      :disabled="editorDisabled"
      :options="editorOptions.editorOption"
      class="answer-edit-box">
    </quill-editor>

    <el-upload
      action="/node_server/filesInfo/uploadImageByGroupId"
      :show-file-list="false"
      accept=".jpg,.jpeg,.png"
      :data="evaluateUploadData"
      :headers="evaluateUploadHeaders"
      :on-success="evaluateUploadSuccess"
      :before-upload="beforeAvatarUpload"
      style="display: none;"
    >
      <el-icon :id="elUploadId"><Plus/></el-icon>
    </el-upload>
  </div>
</template>
<script>

// 引入富文本
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor/src";
import { ElMessage } from 'element-plus'
import { getCookie } from '@/net/cookies'
import { defaultUtils } from '@/utils/defaultUtils'
import defaultConfig from '@/config/defaultConfig'

export default {
  name: 'QuillEditorInfo',
  components: {
    quillEditor
  },
  props: {
    editorDisabled: {
      type: Boolean,
      default: false
    },
    limitImageCount: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      hasUserLogin: false,

      editorOptions: {
        content: '',
        editorOption: {
          // 占位配置
          placeholder: '',
          modules: {
            clipboard: {
              // 粘贴版，处理粘贴时候带图片
              matchers: [[Node.ELEMENT_NODE, this.handleCustomMatcher]],
            },
            toolbar: {
              container: [
                // ['bold', 'italic', 'underline'], //加粗，斜体，下划线，删除线
                [{ header: 1 }, { header: 2 }], // 标题
                // [{ list: 'ordered' }, { list: 'bullet' }], // 列表
                [{ indent: '-1' }, { indent: '+1' }], // 缩进
                // [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
                [{ align: [] }], // 对齐方式
                ['image'],  // 图片
              ],
              handlers: {
                'image': this.selectLocalImage
              }
            }
          }
        }
      },
      elUploadId: 'myQuillEditorUpload' + new Date().getTime(),
      evaluateUploadHeaders: {Authorization: ''},
      evaluateUploadData: {groupId: ''},
      uploadImages: [], // 已经上传的图片
    }
  },
  mounted () {
    this.init();
  },
  beforeUnmount () {
  },
  methods: {
    init(){
      // 判断是否登录
      this.checkUserLogin();
      // 初始化评论信息
      this.evaluateUploadData.groupId = defaultUtils.getUuidString();
    },
    checkUserLogin(){
      // 获取用户信息
      try{
        let userInfoStr = getCookie("userInfo");
        let userInfo = JSON.parse(userInfoStr);
        if(userInfo.id){
          this.hasUserLogin = true;
        }
      } catch (e) {

      }
    },
    handleCustomMatcher (node, Delta) {
      let ops = []
      Delta.ops.forEach(op => {
        if (op.insert && typeof op.insert === 'string') {// 如果粘贴了图片，这里会是一个对象，所以可以这样处理
          ops.push({
            insert: op.insert,
          })
        } else {
          if (op.insert.image.includes('file://') || op.insert.image.includes('data:image')) {  //本地图片会使文件file开头
            ElMessage.warning('不允许粘贴图片,请手动上传')
          } else {
            ops.push({
              insert: op.insert,
            })
          }
        }
      })
      Delta.ops = ops
      return Delta
    },
    selectLocalImage(value){
      if(value){
        // 调用element的图片上传功能，上传图片
        if(!this.hasUserLogin){
          ElMessage.warning("请先登录")
          return;
        }
        if(this.getEditorImageCount() >= this.limitImageCount){
          ElMessage.warning(`最多允许上传${this.limitImageCount}张图片`)
          return;
        }
        document.getElementById(this.elUploadId).click();
      }
    },
    getEditorContent(){
      // 获取编辑器的内容
      const editor = this.$refs.myQuillEditor.quill;
      const html = editor.root.innerHTML;
      return html;
    },
    // 获取没有使用的图片id列表
    getNoUseImageIdList(){
      const editor = this.$refs.myQuillEditor.quill;
      const html = editor.root.innerHTML;
      let list = [];
      for(let imageItem of this.uploadImages){
        if(html.includes(imageItem.imageUrl)){
          continue;
        }
        list.push(imageItem.id);
      }
      return list;
    },
    clearEditorContent(){
      const editor = this.$refs.myQuillEditor.quill;
      editor.setContents('');
      this.uploadImages = [];
    },
    setEditorContent(content){
      const editor = this.$refs.myQuillEditor.quill;
      console.log(content)
      editor.root.innerHTML = content;
      this.uploadImages = [];
    },
    getEditorImageCount(){
      const quill = this.$refs.myQuillEditor.quill;
      const html = quill.root.innerHTML;
      const imgTags = html.match(/<img[^>]*>/gi);
      return imgTags ? imgTags.length : 0;
    },
    beforeAvatarUpload(rawFile){
      if (!(rawFile.type === 'image/jpeg' || rawFile.type === 'image/png')) {
        ElMessage.error('只能上传图片类型')
        return false
      } else if (rawFile.size / 1024 / 1024 > 1) {
        ElMessage.error('最大只能上传1MB的图片')
        return false
      }
      return true
    },
    evaluateUploadSuccess(res){
      if(res && res.success && res.content){
        let imageUrl = defaultConfig.server_file_root + res.content.file_path;
        let quill = this.$refs.myQuillEditor.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 将img标签插入到编辑器里面
        quill.insertEmbed(length, 'image', imageUrl);
        // 调整光标到最后
        quill.setSelection(length + 1);
        let imageItem = res.content;
        imageItem.imageUrl = imageUrl;
        this.uploadImages.push(imageItem);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.quill-editor-info{
  position: relative;
  .disabled-box{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.1);
    line-height: 160px;
    color: #8c8c8c;
    text-align: center;
    font-size: 16px;
  }
}
</style>
<style lang="scss">
.quill-editor-info{
  .ql-container{
    min-height: 120px;
  }
}
</style>
