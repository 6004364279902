<template>
  <div class="interact-info" >
    <div class="content" v-loading="queryLoading">
      <div class="header-content">
        <div class="header-search-box">
          <div class="query-item">
            <span class="query-item-label"></span>
            <el-input
              v-model="queryParams.search"
              placeholder="请输入搜索内容"
              class="query-item-input">
              <template #append>
                <el-button :icon="Search" @click="searchBtn()"/>
              </template>
            </el-input>
          </div>
          <div class="query-item">
            <span class="query-item-label"></span>
            <el-cascader v-model="queryParams.interact_type_list" :options="interactTypeTree"
                         :props="{ checkStrictly: true, value: 'name', label: 'name'}"
                         placeholder="类型" clearable @change="searchBtn()" />
          </div>
        </div>
        <div class="header-right-box">
          <el-button class="header-right-box-btn" type="default"  @click="clickAddQuestion">
            <el-icon><Plus /></el-icon> 问题
          </el-button>
        </div>
      </div>
      <div class="main-content">
        <div class="main-content-list custom-overflow" >
          <div class="nodata" v-if="pageData.total === 0">
            暂无数据
          </div>
          <div class="content-list-box" v-else>
            <template v-for="(item, index) in pageData.rows" :key="index" >
              <div class="content-list-item">
                <div class="list-item-header">
                  <div class="list-item-title">
                    <router-link :to="`/interact_detail_info?id=${item.id}`">{{item.title}}</router-link>
                  </div>
                  <div class="list-item-topup" v-if="item.is_top_up === 1">
                    <i class="iconfont icon-zhiding"></i>
                    <span>精选</span>
                  </div>
                </div>
                <div class="list-item-interact-type">
                  <template v-for="(item1, index1) in item.interact_type_list" :key="index1">
                    <el-tag type="warning">{{item1}}</el-tag>
                  </template>
                </div>
                <div class="list-item-footer">
                  <div class="list-item-create-time">{{item.user_name}}</div>
                  <div class="list-item-create-time">{{item.create_time}}</div>
                  <div class="list-item-footer-item" @click="clickOpenItem(item)">
                    <i class="iconfont icon-review"></i>
                    <span>{{item.answer_count}}</span>
                  </div>
                  <div class="list-item-footer-item" @click="clickVoteBtn(item)">
                    <i class="iconfont icon-dianzan0" v-if="item.has_vote == 0"></i>
                    <i class="iconfont icon-dianzan1" v-else></i>
                    <span>{{item.vote_count}}</span>
                  </div>
                  <div class="list-item-footer-item" @click="clickOpenItem(item)">
                    <i class="iconfont icon-yuedu"></i>
                    <span>{{item.reading_quantity}}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="main-content-pagination">
          <div class="total">记录数: {{pageData.total}}条</div>
          <el-pagination background layout="prev, pager, next" :page-size="pageData.pageSize" :current-page="pageData.currentPage" :total="pageData.total" @current-change="currentChange" />
        </div>
      </div>
    </div>
  </div>

  <el-dialog
    v-model="openCreateInfoFlag"
    title="提问问题"
    class="edit-info-dialog"
    :close-on-click-modal="false"
  >
    <div class="edit-info-dialog-content">
      <el-form :model="createEntityInfo" label-width="auto" :rules="createEntityRules" @submit.native.prevent ref="CreateInfoFormRef" >
        <div class="info-group">
          <div class="info-row">
            <el-form-item class="info-item" label="标题" prop="title">
              <el-input class="info-item-input" v-model="createEntityInfo.title" />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="内容" prop="content">
              <QuillEditorInfo ref="QuillEditorContentInfo" class="custom-editor"></QuillEditorInfo>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="btn-group">
        <el-button type="primary" @click="saveQuestionInfoBtn" v-loading="saveQuestionInfoLoading">提问</el-button>
      </div>
    </div>
  </el-dialog>

</template>

<script>

import { getCookie } from '@/net/cookies'
import {Search} from "@element-plus/icons-vue";
import nodeServerInteract from "@/net/nodeServerInteract";
import {ElMessage} from "element-plus";
import QuillEditorInfo from '@/views/QuillEditorInfo.vue'

export default {
  name: 'InteractInfo',
  computed: {
    Search() {
      return Search
    }
  },
  components: {
    QuillEditorInfo

  },
  data () {
    return {
      hasUserLogin: false,
      queryLoading: false,
      queryParams: {
        search: '',
        interact_type_list: [],
      },
      interactTypeTree: [],

      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      // 创建问题窗口是否打开标识
      openCreateInfoFlag: false,
      createEntityInfo: {
        title: '',
        content: '',
        interact_type: '',
      },
      createEntityRules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { max: 50, message: '最大长度50', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入提问内容', trigger: 'blur' },
          { max: 2000, message: '最大长度2000', trigger: 'blur' },
        ],
      },
      saveQuestionInfoLoading: false,
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    async init () {
      // 获取用户是否登录
      this.checkUserLogin();
      // 获取类型tree
      await this.initTypeTree();
      // 获取问题列表信息
      await this.searchBtn();
    },
    checkUserLogin(){
      // 获取用户信息
      try{
        let userInfoStr = getCookie("userInfo");
        let userInfo = JSON.parse(userInfoStr);
        if(userInfo.id){
          this.hasUserLogin = true;
        }
      } catch (e) {

      }
    },
    async initTypeTree(){
      let res = await nodeServerInteract.interactTypeInfo_getTypeTree({});
      if(res.success && res.content){
        this.interactTypeTree = res.content;
      }
    },
    async searchBtn(){
      this.queryLoading = true;
      let interact_type = '';
      if(this.queryParams.interact_type_list && this.queryParams.interact_type_list.length > 0){
        interact_type = this.queryParams.interact_type_list.join('-');
      }
      // 查询列表
      let searchParams = {
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        search: this.queryParams.search,
        interact_type: interact_type,
      }
      let res = await nodeServerInteract.interactQuestionInfo_getPage(searchParams);
      if(res.success && res.content){
        // 处理列表数据
        console.log(res.content.rows)
        for(let item of res.content.rows){
          let interact_type_list = [];
          if(item.interact_type){
            interact_type_list = item.interact_type.split(',');
          }
          console.log(interact_type_list)
          item.interact_type_list = interact_type_list;
        }
        console.log(res.content.rows)

        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }
      this.queryLoading = false;
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.searchBtn()
    },
    async clickAddQuestion(){
      // 判断是否登录
      if(!this.hasUserLogin){
        // 再重新获取一次
        await this.checkUserLogin();
      }
      if(!this.hasUserLogin) {
        // 没有登录， 打开登录页面
        this.$emit('childEmit', 1);
        ElMessage('请先登录.')
        return;
      }
      //  打开窗口创建问题
      this.openCreateInfoFlag = true;
      // 初始化窗口里面内容
      this.createEntityInfo = {
        title: '',
        content: '',
        interact_type: '',
      }

    },
    async saveQuestionInfoBtn(){
      // 保存信息到服务器
      // CreateInfoFormRef
      // 保存数据到服务器
      const myComponentInstance = this.$refs.CreateInfoFormRef;
      let content = this.$refs.QuillEditorContentInfo.getEditorContent();
      if(!(content === '<p><br></p>' || content.replaceAll(' ', '') ==='<p></p>')){
        this.createEntityInfo.content = content;
      } else {
        this.createEntityInfo.content = '';
      }

      if (!myComponentInstance) return
      await myComponentInstance.validate((valid, fields) => {
        if (valid) {
          this.saveQuestionInfoLoading = true;
          this.saveQuestionInfoToService();
          this.saveQuestionInfoLoading = false;
        } else {
          ElMessage.error('请先完成信息填写');
        }
      })
    },
    async saveQuestionInfoToService(){
      let entityInfo = JSON.parse(JSON.stringify(this.createEntityInfo));
      let res = await nodeServerInteract.interactQuestionInfo_create(entityInfo);
      if(res.success){
        // 插入成功， 关闭弹窗，刷新列表
        ElMessage.success('保存成功， 审核成功后将会展示出来');
        // 清空富文本内容
        this.$refs.QuillEditorContentInfo.clearEditorContent();

        // 关闭弹窗
        this.openCreateInfoFlag = false;
      } else {
        if(res.message && res.message.length < 50){
          ElMessage.error(res.message);
        }else{
          ElMessage.error('保存失败，请联系小哥');
        }
      }
    },
    async clickVoteBtn(item){
      console.log(item);
      if(!this.hasUserLogin){
        ElMessage.info('登录后才能进行点赞');
      } else {
        if(item.has_vote > 0){
          // 点赞过了，取消点赞
          let res = await nodeServerInteract.interactVoteInfo_cancelVoteById({
            business_type: 1,
            business_id: item.id
          })
          if(res.success){
            ElMessage.success('取消点赞成功');
            item.has_vote = 0;
            item.vote_count--;
          }else{
            ElMessage.error('取消点赞失败');
          }
        } else {
          // 点赞
          let res = await nodeServerInteract.interactVoteInfo_voteById({
            vote_type: 1,
            business_type: 1,
            business_id: item.id
          })

          if(res.success){
            ElMessage.success('点赞成功');
            item.has_vote = 1;
            item.vote_count++;
          }else{
            ElMessage.error('点赞失败');
          }
        }
      }
    },
    clickOpenItem(item){
      this.$router.push({ path: `/interact_detail_info`, query: {id: item.id}})
    }
  }
}
</script>
<style lang="scss" scoped>
.interact-info{
  width: 100%;
  font-size: 14px;
  position: relative;

  .content {
    min-height: 800px;
    margin: 24px 240px 48px 240px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 水平偏移 垂直偏移 模糊半径 颜色 */
    border-radius: 20px;

    .header-content {
      height: 60px;
      display: flex;
      align-items: center; /* 垂直居中 */
      .header-search-box{
        height: 100%;
        width: calc(100% - 160px);
        display: flex;
        align-items: center; /* 垂直居中 */
        .query-item{
          margin-left: 32px;
          .query-item-label{
            text-align: right;
          }
          .query-item-input{
            //margin-left: 12px;
            width: 320px;
          }
        }
        .btn-group{
          margin-left: 64px;
          line-height: 64px;
          .el-button{
            margin-right: 12px;
            margin-bottom: 6px;
          }
        }
      }
      .header-right-box{
        .header-right-box-btn{
          font-size: 16px;
          cursor: pointer;
          height: 36px;
          line-height: 36px;
          width: 120px;
          border-radius: 8px;
          .el-icon{
            margin-right: 8px;
            font-size: 16px;
          }
        }
      }
    }
    .main-content{
      width: 100%;
      height: 720px;
      background: linear-gradient(to bottom, #f0f0f0, #fff 50%);
      .main-content-list{
        width: 100%;
        height: calc(100% - 60px);
        overflow-y: auto;
        overflow-x: hidden;
        .nodata{
          height: 100%;
          width: 100%;
          text-align: center;
          line-height: 240px;
          font-size: 20px;
        }
        .content-list-box{
          width: 100%;
          font-size: 14px;
          .content-list-item{
            margin: 16px 32px;
            border-bottom: 1px solid #e8e8e8;
            .list-item-header{
              display: flex;
              height: 40px;
              line-height: 40px;
              .list-item-title{
                font-size: 20px;
                width: calc(100% - 80px);
                white-space: nowrap; /* 确保文本不换行 */
                overflow: hidden; /* 隐藏溢出的内容 */
                text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
                a{
                  color: #409eff;
                }
                a:hover {
                  color: rgb(121.3, 187.1, 255);
                }
              }
              .list-item-topup{
                color: #409eff;
                i{
                  margin-right: 8px;
                }
              }
            }
            .list-item-interact-type{
              margin: 0;
              .el-tag{
                margin-right: 12px;
                margin-bottom: 4px;
              }
            }
            .list-item-content{
              margin: 8px 16px;
              line-height: 20px;
              white-space: nowrap; /* 确保文本不换行 */
              overflow: hidden; /* 隐藏溢出的内容 */
              text-overflow: ellipsis; /* 使用省略号显示溢出的文本 */
            }
            .list-item-footer{
              margin-top: 16px;
              margin-bottom: 12px;
              display: flex;
              .list-item-create-time{
                margin-right: 32px;
                margin-left: 8px;
              }
              .list-item-footer-item{
                cursor: pointer;
                &:hover{
                  color: rgb(121.3, 187.1, 255);
                }
                i{
                  margin-left: 20px;
                  margin-right: 6px;
                }
              }
            }
          }
        }
      }
      .main-content-pagination{
        width: 100%;
        margin-top: 24px;
        position: relative;
        justify-content: right;
        display: flex;
        .el-pagination{
          margin-right: 32px;
        }
        .total{
          line-height: 40px;
          font-size: 14px;
          margin-right: 24px;
        }
      }
    }
  }
}

//弹窗
.edit-info-dialog-content{
  padding: 12px 20px 24px 20px;
  .info-group{
    .info-group-title{
      height: 24px;
      line-height: 22px;
      font-size: 14px;
      border-bottom: 1px solid #8c8c8c;
      margin-bottom: 16px;
    }
    .info-row{
      display: flex;
      .info-item{
        flex: 1;
        margin-right: 32px;
        .tag-input, .keyword-button-new-tag{
          width: 160px !important;
        }
        .el-tag{
          margin-right: 8px;
          margin-bottom: 4px;
        }
        .tip-text{
          font-size: 12px;
          margin-left: 12px;
          color: #8c8c8c;
        }
        .custom-editor{
          width: 100%;
        }
      }
    }
  }
  .btn-group{
    text-align: center;
  }
}
</style>
<style lang="scss">
.edit-info-dialog{
  width: 800px !important;
  .el-dialog__body{
    padding: 0;
  }
}
</style>

