
<template>
  <div class="manage-interact-type-info" >
    <div class="table-content custom-overflow">
      <el-table :data="interactTypeTree" stripe style="width: 100%" show-overflow-tooltip row-key="id">
        <el-table-column prop="id" label="id" />
        <el-table-column prop="sort_no" label="排序" />
        <el-table-column prop="code" label="编号"/>
        <el-table-column prop="name" label="名称"  />
        <el-table-column prop="full_name" label="全称"  />
        <el-table-column prop="create_time" label="创建时间" />
      </el-table>
    </div>
  </div>

</template>

<script>

import nodeServer from '../net/nodeServer'
import {dateUtils} from '../utils/dateUtils'
import nodeServerRelease from '@/net/nodeServerRelease'
import { ElMessage } from 'element-plus'
import defaultConfig from '@/config/defaultConfig'
import nodeServerInteract from "@/net/nodeServerInteract";

export default {
  name: 'ManageInteractTypeInfo',
  components: {

  },
  data () {
    return {
      interactTypeTree: [],
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    async init () {
      await this.initTypeTree();
    },
    async initTypeTree(){
      let res = await nodeServerInteract.interactTypeInfo_getTypeTree({});
      if(res.success && res.content){
        this.interactTypeTree = res.content;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.manage-interact-type-info{
  width: 100%;
  height: 100%;
  .table-header{
    height: 120px;
    line-height: 60px;
    display: flex;
    flex-wrap: wrap;
    .query-item{
      margin-left: 64px;
      .query-item-label{
        text-align: right;
      }
      .query-item-input{
        margin-left: 16px;
        width: 120px;
      }
    }
    .btn-group{
      margin-left: 32px;
      .el-button{
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
  .table-content{
    height: calc(100% - 120px);
    overflow-y: auto;
    .pagination{
      margin-top: 1rem;
      position: relative;
      float: right;
      display: flex;
      .el-pagination{
        margin-right: 32px;
      }
      .total{
        line-height: 40px;
        font-size: 14px;
        margin-right: 24px;
      }
    }
    .success-row{
      color: #00c48f;
    }
    .warning-row{
      color: #f44336;
    }
    .cancel-row{
      color: #ff9f00;
    }
  }
}
</style>
