import axiosWraper from './axiosWraper'

const proxyUrl = '/node_server'

const nodeServer = {

  updateCookieToken: () => {
    axiosWraper.updateCookieToken()
  },
  // 用户相关接口
  userInfo_loginBytoken: async params => {
    const url = `${proxyUrl}/userInfo/loginBytoken`
    return axiosWraper.getData(url, params)
  },
  userInfo_loginByWeixinCode: async params => {
    const url = `${proxyUrl}/userInfo/loginByWeixinCode`
    return axiosWraper.post(url, params)
  },
  userInfo_loginWebByWeixinCode: async params => {
    const url = `${proxyUrl}/userInfo/loginWebByWeixinCode`
    return axiosWraper.post(url, params)
  },
  // pageSize: 10, pageNum: 1, id: xxx, vipType: 是否会员（1：否， 2： 是）, isManager: 是否管理
  userInfo_getPage: async params => {
    const url = `${proxyUrl}/userInfo/getPage`
    return axiosWraper.getData(url, params)
  },
  // 会员商品列表接口
  memberInfo_getPage: async params => {
    const url = `${proxyUrl}/memberInfo/getPage`
    return axiosWraper.getData(url, params)
  },
  // 获取商品微信支付二维码 {code: year}
  weixinPay_createNativeCode: async params => {
    const url = `${proxyUrl}/weixinPay/createNativeCode`
    return axiosWraper.getData(url, params)
  },
  // 订单接口-根据订单号获取
  orderInfo_getOneByOutTradeNo: async params => {
    const url = `${proxyUrl}/orderInfo/getOneByOutTradeNo`
    return axiosWraper.getData(url, params)
  },
  // pageSize: 10, pageNum: 1, userId: xxx
  orderInfo_getPage: async params => {
    const url = `${proxyUrl}/orderInfo/getPage`
    return axiosWraper.getData(url, params)
  },
  // 应用版本接口-获取默认应用
  applicationInfo_getOneByIsDefault: async params => {
    const url = `${proxyUrl}/applicationInfo/getOneByIsDefault`
    return axiosWraper.getData(url, params)
  },
  // pageSize: 10, pageNum: 1
  applicationInfo_getPage: async params => {
    const url = `${proxyUrl}/applicationInfo/getPage`
    return axiosWraper.getData(url, params)
  },
  // 设置默认应用{id: xxx}
  applicationInfo_setDefaultById: async params => {
    const url = `${proxyUrl}/applicationInfo/setDefaultById`
    return axiosWraper.post(url, params)
  },

  /**
   * 创建应用
   * @param params
   * {
   *    name": "审计小哥excel工具",
   *    remarks": "1、支持wps安装",
   *    version": "v1.0.8",
   *    file_group_id": "test"
   * }
   * @returns
   */
  applicationInfo_create: async params => {
    const url = `${proxyUrl}/applicationInfo/create`
    return axiosWraper.post(url, params)
  },
  // 获取统计数据
  dataCount_getOverviewDataCount: async params => {
    const url = `${proxyUrl}/dataCount/getOverviewDataCount`
    return axiosWraper.getData(url, params)
  },

  // filesInfo/uploadImageByGroupId


  /**
   * 删除附件（只能删除自己上传的）
   * @param params
   * {
   *    id": "id",
   * }
   * @returns
   */
  filesInfo_deleteById: async params => {
    const url = `${proxyUrl}/filesInfo/deleteById`
    return axiosWraper.post(url, params)
  },
  /**
   * 行政区划接口
   * @param params
   * {
   *    "region_code": "当前级别区划  100000",
   *    "region_level": "下一级区划 1",
   * ｝
   * @returns
   */
  regionInfo_getNextByRegionCodeAndLevel: async params => {
    const url = `${proxyUrl}/regionInfo/getNextByRegionCodeAndLevel`
    return axiosWraper.getData(url, params)
  },

  /**
   * 行政区划接口
   * @param params
   * {
   * ｝
   * @returns
   */
  regionInfo_getRegionTree: async params => {
    const url = `${proxyUrl}/regionInfo/getRegionTree`
    return axiosWraper.getData(url, params)
  },

  /**
   * 获取数据字典
   * @param params
   * {
   *    "dicts": "release_business_type,release_examine_type,full_time_type",
   * ｝
   * @returns
   */
  regionInfo_getDictInfo: async (params = {}) => {
    if(!params.dicts){
      params.dicts = 'common_true_or_false,release_business_type,release_examine_type,release_examine_status,full_time_type,' +
        'interact_business_type,interact_examine_type,interact_examine_status';
    }
    const url = `${proxyUrl}/regionInfo/getDictInfo`
    return axiosWraper.getData(url, params)
  },

  // 问题答疑模块
  /**
   * 根据id获取问题分组列表
   * @param params
   * {
   *    "pageNum": 1,
   *    "pageSize": 100,
   *    "id": "id",
   *    "group_type": 1,    //分组类型(1: excel工具, 2： word工具, 3: 问题答疑)
   *    "show_all": 1,    //显示所有
   * ｝
   * @returns
   */
  questionGroupInfo_getPage: async params => {
    const url = `${proxyUrl}/questionGroupInfo/getPage`
    return axiosWraper.getData(url, params)
  },
  /**
   * 根据id获取问题分组详情
   * @param params
   * {
   *    "id": "id",
   * ｝
   * @returns
   */
  questionGroupInfo_getDetailById: async params => {
    const url = `${proxyUrl}/questionGroupInfo/getDetailById`
    return axiosWraper.getData(url, params)
  },
  /**
   * 创建问题分组
   * @param params
   * {
   *     group_name  : "安装报错xxx权限不足",
   *     group_info  : "安装报错xxx权限不足xxxxxxx",
   *     sort_no     : 0,
   *     group_type  : 3,    //'分组类型(1: excel工具, 2： word工具, 3: 问题答疑)'
   *     is_active   : 0
   * ｝
   * @returns
   */
  questionGroupInfo_create: async params => {
    const url = `${proxyUrl}/questionGroupInfo/create`
    return axiosWraper.post(url, params)
  },
  questionGroupInfo_update: async params => {
    const url = `${proxyUrl}/questionGroupInfo/update`
    return axiosWraper.post(url, params)
  },
  /**
   * 创建问题分组详情
   * @param params
   * {
   *     group_id  : "分组id",
   *     detail_info  : "安装报错xxx权限不足xxxxxxx",
   *     sort_no     : 0,
   *     file_group_id  : '',
   * ｝
   * @returns
   */
  questionDetailInfo_create: async params => {
    const url = `${proxyUrl}/questionDetailInfo/create`
    return axiosWraper.post(url, params)
  },
  questionDetailInfo_update: async params => {
    const url = `${proxyUrl}/questionDetailInfo/update`
    return axiosWraper.post(url, params)
  },

  // 意见反馈
  /**
   * 创建意见反馈
   * @param params {feedback_content: xxx}
   * @returns {AxiosPromise<any>}
   */
  feedbackInfo_create: async params => {
    const url = `${proxyUrl}/feedbackInfo/create`
    return axiosWraper.post(url, params)
  },
  /**
   * 查询意见反馈
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  feedbackInfo_getPage: async params => {
    const url = `${proxyUrl}/feedbackInfo/getPage`
    return axiosWraper.getData(url, params)
  },
}

export default nodeServer
