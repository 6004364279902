<template>
  <div class="interact-detail-info">
    <div class="content" v-loading="queryLoading">
      <div class="content-title">{{questionInfo.title}}</div>
      <div class="content-interact-type">
        <template v-for="(item1, index1) in questionInfo.interact_type_list" :key="index1">
          <el-tag type="warning">{{item1}}</el-tag>
        </template>
      </div>
      <div class="title-footer">
        <div class="list-item-create-time">{{questionInfo.user_name}}</div>
        <div class="list-item-create-time">{{questionInfo.create_time}}</div>
        <div class="list-item-footer-item">
          <i class="iconfont icon-yuedu"></i>
          <span>{{questionInfo.reading_quantity}}</span>
        </div>
      </div>

      <div class="content-content-html custom-editor" v-html="questionInfo.content"></div>

      <div class="content-answer-box">
        <el-divider content-position="left">{{articleText}}列表</el-divider>
        <div class="content-answer-list">
          <div class="nodata" v-if="pageData.total === 0">暂无数据</div>
          <div class="answer-list-box" v-else>
            <div v-for="(item, index) in pageData.rows" :key="index" class="answer-list-item">
              <div class="answer-list-item-left">
                <div class="answer-list-item-left-image-info">
                  <i class="iconfont icon-yonghufill"></i>
                </div>
              </div>
              <div class="answer-list-item-right">
                <div class="list-item-header">
                  <div class="list-item-create-time">{{item.user_name}}</div>
                </div>
                <div class="list-item-content custom-editor" v-html="item.content"></div>
                <div class="list-item-footer">
                  <div class="list-item-create-time">{{item.create_time}}</div>
                  <div class="list-item-footer-item" @click="clickVoteBtn(item, 1)">
                    <i class="iconfont icon-dianzan0" v-if="item.has_vote1 == 0"></i>
                    <i class="iconfont icon-dianzan1" v-else></i>
                    <span>{{item.vote1_count}}</span>
                  </div>
                  <div class="list-item-footer-item" @click="clickVoteBtn(item, 2)">
                    <i class="iconfont icon-fandui0" v-if="item.has_vote2 == 0"></i>
                    <i class="iconfont icon-fandui1" v-else></i>
                    <span>{{item.vote2_count}}</span>
                  </div>
                  <div class="list-item-footer-add-answer" @click="clickAddAnswerBtn(item)">回复</div>
                </div>
                <div class="list-item-children" v-if="item.childCount > 0">
                  <div class="list-item-children-list">
                    <div class="list-item-children-list-item" v-for="(item1, index1) in item.children" :key="index1">
                      <div class="list-item-children-list-item-left">
                        <div class="list-item-children-list-item-left-image-info">
                          <i class="iconfont icon-yonghufill"></i>
                        </div>
                      </div>
                      <div class="list-item-children-list-item-right">
                        <div class="list-item-header">
                          <div class="list-item-create-time">{{item1.user_name}}</div>
                        </div>
                        <div class="list-item-content custom-editor" v-html="item1.content"></div>
                        <div class="list-item-footer">
                          <div class="list-item-create-time">{{item1.create_time}}</div>
                          <div class="list-item-footer-item" @click="clickVoteBtn(item1, 1)">
                            <i class="iconfont icon-dianzan0" v-if="item1.has_vote1 == 0"></i>
                            <i class="iconfont icon-dianzan1" v-else></i>
                            <span>{{item1.vote1_count}}</span>
                          </div>
                          <div class="list-item-footer-item" @click="clickVoteBtn(item1, 2)">
                            <i class="iconfont icon-fandui0" v-if="item1.has_vote2 == 0"></i>
                            <i class="iconfont icon-fandui1" v-else></i>
                            <span>{{item1.vote2_count}}</span>
                          </div>
                          <div class="list-item-footer-add-answer" @click="clickAddAnswerBtn(item1)">回复</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-item-children-footer">
                    <div class="list-item-children-divider"></div>
                    <div class="list-item-children-btn" v-if="item.children.length === 0" @click="getAnswerChildrenPage(item)">
                      <span>展开{{item.childCount}}条</span>
                      <span>{{articleText}}</span>
                      <i class="iconfont icon-xiangxia"></i>
                    </div>
                    <div class="list-item-children-btn" v-if="item.children.length > 0 && item.children.length < item.childCount" @click="getAnswerChildrenPage(item)">
                      <span>展开更多{{articleText}}</span>
                      <i class="iconfont icon-xiangxia"></i>
                    </div>
                    <div class="list-item-children-btn" v-if="item.children.length > 0" @click="closeAnswerChildrenPage(item)">
                      <span>收起</span>
                      <i class="iconfont icon-xiangshang"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-pagination">
            <div class="total">回答数: {{pageData.total}}条</div>
            <el-pagination background layout="prev, pager, next" :page-size="pageData.pageSize" :current-page="pageData.currentPage" :total="pageData.total" @current-change="currentChange" />
          </div>
        </div>
        <div class="content-answer-edit" v-loading="saveAnswerLoading">
          <el-divider content-position="left"></el-divider>
          <QuillEditorInfo :editor-disabled="editorOptions.disabled" ref="MyQuillEditorInfo" class="custom-editor"></QuillEditorInfo>
          <el-button @click="clickSaveAnswer" class="answer-edit-btn">提交{{articleText}}</el-button>
        </div>
      </div>
    </div>
  </div>

  <el-dialog
    v-model="openCreateInfoFlag"
    title="回复内容编辑"
    class="edit-info-dialog"
    :close-on-click-modal="false"
  >
    <div class="edit-info-dialog-content" v-if="openCreateInfoFlag" v-loading="saveAnswerReplyLoading">
      <QuillEditorInfo ref="QuillEditorReplyInfo" class="custom-editor"></QuillEditorInfo>
      <div class="btn-group">
        <el-button type="primary" @click="clickSaveReplyAnswer" >回复</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>

import { getCookie } from '@/net/cookies'
import nodeServerInteract from "@/net/nodeServerInteract";
import {ElMessage} from "element-plus";
import QuillEditorInfo from '@/views/QuillEditorInfo.vue';

export default {
  name: 'InteractDetailInfo',
  components: {
    QuillEditorInfo,
  },
  data () {
    return {
      hasUserLogin: false,
      queryLoading: false,

      questionId: '',
      questionInfo: {},
      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      editorOptions: {
        content: '',
        disabled: true
      },
      saveAnswerLoading: false,
      saveAnswerReplyLoading: false,

      articleText: '回答',

      // 创建问题窗口是否打开标识
      openCreateInfoFlag: false,
      createEntityInfo: {
        parent_id: '',
        content: '',
      },
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    async init () {
      // 获取路由参数
      this.questionId = this.$route.query.id;
      // 获取用户是否登录
      this.checkUserLogin();
      // 获取问题信息
      await this.getInteractQuestionInfo();
      if(this.questionInfo.is_article === 1){
        this.articleText = '评论';
      }
      // 阅读量 + 1
      await this.addReadingQuantityById();
      // 获取回答列表信息
      await this.getAnswerPage();
      // 获取自己回答未审核通过的信息
      await this.getAnswerPageBySelf();
    },
    checkUserLogin(){
      // 获取用户信息
      try{
        let userInfoStr = getCookie("userInfo");
        let userInfo = JSON.parse(userInfoStr);
        if(userInfo.id){
          this.hasUserLogin = true;
          this.editorOptions.disabled = false;
        }
      } catch (e) {

      }
    },
    async getInteractQuestionInfo(){
      if(this.questionId){
        let res = await nodeServerInteract.interactQuestionInfo_getOneById({id: this.questionId});
        if(res.success && res.content){
          let item = res.content;

          let interact_type_list = [];
          if(item.interact_type){
            interact_type_list = item.interact_type.split(',');
          }
          item.interact_type_list = interact_type_list;

          this.questionInfo = item;
          console.log(item)
        }
      }
    },
    async addReadingQuantityById(){
      if(this.questionInfo && this.questionInfo.id){
        await nodeServerInteract.interactQuestionInfo_addReadingQuantityById({id: this.questionId});
      }
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.getAnswerPage();
    },
    async clickSaveAnswer(){
      if(!this.hasUserLogin){
        ElMessage.warning("请先登录")
        return;
      }
      // 获取富文本内容
      let content = this.$refs.MyQuillEditorInfo.getEditorContent();
      if(content === '<p><br></p>' || content.replaceAll(' ', '') ==='<p></p>'){
        ElMessage.warning("请先填写回答")
        return;
      }
      this.saveAnswerLoading = true;
      // 获取需要删除的附件id
      // let fileIdList = this.$refs.MyQuillEditorInfo.getNoUseImageIdList();
      // 保存到服务器
      let res = await nodeServerInteract.interactAnswerInfo_create({
        question_id: this.questionId,
        content: content,
      });
      if(res.success){
        // 插入成功， 关闭弹窗，刷新列表
        ElMessage.success('提交成功， 审核成功后将会展示出来');
      } else {
        if(res.message && res.message.length < 50){
          ElMessage.error(res.message);
        }else{
          ElMessage.error('保存失败，请联系小哥');
        }
      }
      this.saveAnswerLoading = false;
    },
    // 获取回答分页
    async getAnswerPage(){
      let res = await nodeServerInteract.interactAnswerInfo_getPage({
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        question_id: this.questionId,
        parent_id: 0
      })
      if(res.success && res.content){
        // 循环处理数据
        for(let item of res.content.rows){
          item.has_vote1 = item.has_vote1 ? item.has_vote1 : 0;
          item.has_vote2 = item.has_vote2 ? item.has_vote2 : 0;
          item.vote1_count = item.vote1_count ? item.vote1_count : 0;
          item.vote2_count = item.vote2_count ? item.vote2_count : 0;
          item.childCount = item.childCount ? item.childCount : 0;
          item.children = [];
        }
        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }

    },
    // 获取回答的子节点分页
    async getAnswerChildrenPage(item){
      // 判断当前应该获取第几页
      let pageNum = 1;
      let pageSize = 5;
      if(item.children.length > 0 && item.children.length < item.childCount){
        // 计算要获取哪一页
        let count = item.children.length;
        let page = Math.floor(count/pageSize)
        let yushu = count%pageSize;
        if(yushu === 0){
          page += 1;
        } else {
          page += 2;
        }
        pageNum = page;
      }
      // 获取父节点id
      let parent_id = item.id;
      let res = await nodeServerInteract.interactAnswerInfo_getPage({
        pageNum: pageNum,
        pageSize: pageSize,
        question_id: this.questionId,
        parent_id: parent_id
      })
      if(res.success && res.content){
        // 循环处理数据
        for(let item1 of res.content.rows){
          item1.has_vote1 = item1.has_vote1 ? item1.has_vote1 : 0;
          item1.has_vote2 = item1.has_vote2 ? item1.has_vote2 : 0;
          item1.vote1_count = item1.vote1_count ? item1.vote1_count : 0;
          item1.vote2_count = item1.vote2_count ? item1.vote2_count : 0;
          item1.childCount = item1.childCount ? item1.childCount : 0;
          item1.children = [];
        }
        item.childCount = res.content.total;
        item.children = item.children.concat(res.content.rows);
      }
    },
    async closeAnswerChildrenPage(item){
        item.children = [];
    },
    // 获取自己提交还没有审核的回答
    async getAnswerPageBySelf(){
      /* let res = await nodeServerInteract.interactAnswerInfo_getPageBySelf({
        examine_status: 0,
        is_delete: 0,
        question_id: this.questionId
      }) */
    },
    async clickVoteBtn(item, voteType){
      // 保存
      if(!this.hasUserLogin){
        ElMessage.info('登录后才能进行操作');
      } else {
        let messageText = '支持';
        if(voteType === 2){
          messageText = '反对';
        }
        if((voteType === 1 && item.has_vote1 > 0)
          || (voteType === 2 && item.has_vote2 > 0)){
          // 支持或者反对过了，取消
          let res = await nodeServerInteract.interactVoteInfo_cancelVoteById({
            business_type: 2,
            business_id: item.id
          })

          if(res.success){
            ElMessage.success(`取消${messageText}成功`);
            item.has_vote1 = 0;
            item.has_vote2 = 0;
            if(voteType === 1){
              item.vote1_count--;
            } else {
              item.vote2_count--;
            }
          }else{
            ElMessage.error(`取消${messageText}失败`);
          }
        } else {
          // 直接插入
          let res = await nodeServerInteract.interactVoteInfo_voteById({
            vote_type: voteType,
            business_type: 2,
            business_id: item.id
          })

          if(res.success){
            ElMessage.success(`${messageText}成功`);
            // 处理原来有支持或者反对过的
            if(item.has_vote1 > 0){
              item.has_vote1 = 0;
              item.vote1_count--;
            } else if(item.has_vote2 > 0){
              item.has_vote2 = 0;
              item.vote2_count--;
            }
            // 处理当前点击的支持或者反对
            if(voteType === 1){
              item.vote1_count++;
              item.has_vote1 = 1;
            } else {
              item.vote2_count++;
              item.has_vote2 = 1;
            }
          }else{
            ElMessage.error(`${messageText}失败`);
          }
        }
      }
    },
    async clickAddAnswerBtn(item){
      // 点击回复评论按钮(打开新的弹窗维护评论信息)
      // 保存
      if(!this.hasUserLogin){
        ElMessage.info('登录后才能进行操作');
      } else {
        this.createEntityInfo.parent_id = item.parent_id ? item.parent_id : item.id;
        this.openCreateInfoFlag = true;
      }
    },
    // 回复其他人评论
    async clickSaveReplyAnswer(){
      if(!this.hasUserLogin){
        ElMessage.warning("请先登录")
        return;
      }
      // 获取富文本内容
      let content = this.$refs.QuillEditorReplyInfo.getEditorContent();
      if(content === '<p><br></p>' || content.replaceAll(' ', '') ==='<p></p>'){
        ElMessage.warning("请先填写回复内容")
        return;
      }
      this.saveAnswerReplyLoading = true;
      // 获取父节点id
      let parent_id = this.createEntityInfo.parent_id;
      // 保存到服务器
      let res = await nodeServerInteract.interactAnswerInfo_create({
        parent_id: parent_id,
        question_id: this.questionId,
        content: content,
      });
      if(res.success){
        // 插入成功， 关闭弹窗，刷新列表
        ElMessage.success('提交成功， 审核成功后将会展示出来');
        // 刷新个人提交列表
        await this.getAnswerPageBySelf();
      } else {
        if(res.message && res.message.length < 50){
          ElMessage.error(res.message);
        }else{
          ElMessage.error('保存失败，请联系小哥');
        }
      }
      this.saveAnswerReplyLoading = false;
      this.openCreateInfoFlag = false;
    },
  }
}
</script>
<style lang="scss" scoped>
.interact-detail-info{
  width: 100%;
  font-size: 14px;
  position: relative;

  .content {
    min-height: 800px;
    margin: 24px 240px 48px 240px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 水平偏移 垂直偏移 模糊半径 颜色 */
    border-radius: 20px;
    padding: 24px 32px;

    .content-title{
      min-height: 40px;
      line-height: 40px;
      font-size: 28px;
      margin-bottom: 4px;
    }

    .content-interact-type{
      margin-bottom: 4px;
      .el-tag{
        margin-right: 12px;
        margin-bottom: 4px;
      }
    }

    .title-footer{
      margin-bottom: 32px;
      display: flex;
      color: #8c8c8c;
      font-size: 15px;
      .list-item-create-time{
        margin-left: 8px;
        margin-right: 32px;
      }
      .list-item-footer-item{
        cursor: pointer;
        &:hover{
          color: rgb(121.3, 187.1, 255);
        }
        i{
          margin-left: 20px;
          margin-right: 6px;
          font-size: 14px;
        }
      }
    }
    .content-content-html{
      margin: 0 16px;
      width: calc(100% - 32px);
      font-size: 16px;
    }
    .content-answer-box{
      margin-top: 48px;
      margin-bottom: 48px;
      .content-answer-list{
        .nodata{
          height: 100%;
          width: 100%;
          text-align: center;
          line-height: 120px;
          font-size: 18px;
        }
        .answer-list-box{
          margin: 16px 32px;
          .answer-list-item{
            display: flex;
            margin-bottom: 16px;
            .answer-list-item-left{
              width: 48px;
              .answer-list-item-left-image-info{
                height: 32px;
                width: 32px;
                line-height: 32px;
                text-align: center;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 水平偏移 垂直偏移 模糊半径 颜色 */
                border-radius: 50%;
                i.iconfont{
                  font-size: 24px;
                }
              }
            }
            .answer-list-item-right{
              width: calc(100% - 48px);
            }
            .list-item-header{
              margin-bottom: 12px;
              display: flex;
              font-size: 16px;
              color: #8c8c8c;
            }
            .list-item-content{
              font-size: 16px;
            }
            .list-item-footer{
              margin-top: 16px;
              margin-bottom: 12px;
              display: flex;
              color: #8c8c8c;
              .list-item-create-time{
                margin-right: 32px;
              }
              .list-item-footer-item{
                cursor: pointer;
                &:hover{
                  color: rgb(121.3, 187.1, 255);
                }
                i{
                  margin-left: 32px;
                  margin-right: 6px;
                }
              }
              .list-item-footer-add-answer{
                margin-left: 48px;
                cursor: pointer;
                &:hover{
                  color: rgb(121.3, 187.1, 255);
                }
              }
            }
            .list-item-children{
              .list-item-children-list{
                .list-item-children-list-item{
                  display: flex;
                  .list-item-children-list-item-left{
                    width: 40px;
                    .list-item-children-list-item-left-image-info{
                      height: 24px;
                      width: 24px;
                      line-height: 24px;
                      text-align: center;
                      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 水平偏移 垂直偏移 模糊半径 颜色 */
                      border-radius: 50%;
                      i.iconfont{
                        font-size: 20px;
                      }
                    }
                  }
                  .list-item-children-list-item-right{
                    width: calc(100% - 40px);
                  }
                }
              }
              .list-item-children-footer{
                display: flex;
                color: #656565;
                height: 24px;
                line-height: 24px;

                .list-item-children-divider{
                  margin-top: 12px;
                  height: 1px;
                  border-bottom: solid 1px #656565;
                  width: 32px;
                  margin-right: 16px;
                }
                .list-item-children-btn{
                  margin-right: 32px;
                  cursor: pointer;
                  &:hover{
                    color: rgb(121.3, 187.1, 255);
                  }
                  i{
                    margin-left: 4px;
                  }
                }
              }
            }
          }
        }
        .content-pagination{
          width: 100%;
          margin-top: 24px;
          position: relative;
          justify-content: center;
          display: flex;
          .el-pagination{
            margin-right: 32px;
          }
          .total{
            line-height: 40px;
            font-size: 14px;
            margin-right: 24px;
          }
        }
      }
      .content-answer-edit{
        .answer-edit-btn{
          margin-top: 12px;
        }
      }
    }
  }
}


//弹窗
.edit-info-dialog-content{
  padding: 12px 20px 24px 20px;
  .info-group{
    .info-group-title{
      height: 24px;
      line-height: 22px;
      font-size: 14px;
      border-bottom: 1px solid #8c8c8c;
      margin-bottom: 16px;
    }
    .info-row{
      display: flex;
      .info-item{
        flex: 1;
        margin-right: 32px;
        .tag-input, .keyword-button-new-tag{
          width: 160px !important;
        }
        .el-tag{
          margin-right: 8px;
          margin-bottom: 4px;
        }
        .tip-text{
          font-size: 12px;
          margin-left: 12px;
          color: #8c8c8c;
        }
        .custom-editor{
          width: 100%;
        }
      }
    }
  }
  .btn-group{
    text-align: center;
    margin-top: 16px;
  }
}
</style>
<style lang="scss">
.edit-info-dialog{
  width: 800px !important;
  .el-dialog__body{
    padding: 0;
  }
}
</style>


