
<template>
  <div class="manage-interact-answer-info" >
    <div class="table-header">
      <div class="query-item">
        <span class="query-item-label">id: </span>
        <el-input class="query-item-input" v-model="queryParams.id" />
      </div>
      <div class="query-item">
        <span class="query-item-label">用户id: </span>
        <el-input class="query-item-input" v-model="queryParams.user_id" />
      </div>
      <div class="query-item">
        <span class="query-item-label">问题id: </span>
        <el-input class="query-item-input" v-model="queryParams.question_id" />
      </div>
      <div class="query-item">
        <span class="query-item-label">审核状态: </span>
        <el-select  v-model="queryParams.examine_status" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="审核通过" :value="1"></el-option>
          <el-option label="审核不通过" :value="2"></el-option>
          <el-option label="取消审核" :value="3"></el-option>
        </el-select>
      </div>
      <div class="query-item">
        <span class="query-item-label">是否删除: </span>
        <el-select  v-model="queryParams.is_delete" class=" query-item-input m-2" clearable placeholder="选择">
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
      </div>
      <div class="btn-group">
        <el-button type="primary" @click="searchBtn">查询</el-button>
        <el-button @click="resetBtn">重置</el-button>
      </div>
    </div>
    <div class="table-content custom-overflow">
      <el-table :data="pageData.rows" stripe style="width: 100%" show-overflow-tooltip >
        <el-table-column prop="id" label="id" width="60" />
        <el-table-column prop="question_id" label="问题id" width="80" />
        <el-table-column prop="parent_id" label="父节点id" width="80" />
        <el-table-column prop="user_id" label="用户id" width="80" />
        <el-table-column prop="user_name" label="用户名" width="80" />
        <el-table-column prop="content" label="内容" />
        <el-table-column prop="vote1_count" label="支持数量"   />
        <el-table-column prop="vote2_count" label="反对数量"   />
        <el-table-column label="审核状态" >
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <span :class="{'success-row': scope.row.examine_status == 1, 'warning-row': scope.row.examine_status == 2, 'cancel-row': scope.row.examine_status == 3}">
                {{ scope.row.examine_status_enum }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="is_delete_enum" label="是否删除" />
        <el-table-column prop="update_time" label="更新时间" />
        <el-table-column label="操作" width="140">
          <template #default="scope" >
            <el-button size="small" type="default" @click="showAnswerInfo(scope.$index, scope.row)" >
              查看
            </el-button>
            <el-button size="small" type="danger" @click="deleteAnswerInfoBtn(scope.$index, scope.row)" v-if="scope.row && scope.row.is_delete == 0" >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="total">记录数: {{pageData.total}}条</div>
        <el-pagination background layout="prev, pager, next" :page-size="pageData.pageSize" :current-page="pageData.currentPage" :total="pageData.total" @current-change="currentChange" />
      </div>
    </div>
  </div>

  <el-dialog
    v-model="openDialogFlag"
    title="查看信息"
    class="edit-info-dialog"
    :close-on-click-modal="false"
  >
    <div class="edit-info-dialog-content">
      <el-form label-width="auto"  >
        <div class="info-group">
          <div class="info-group-title">问题信息</div>
          <div class="info-row">
            <el-form-item class="info-item" label="用户名" prop="user_name">
              <el-input class="info-item-input" v-model="questionEntityInfo.user_name" disabled />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="标题" prop="title">
              <el-input class="info-item-input" v-model="questionEntityInfo.title" disabled />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="内容" prop="content">
              <div class="info-item-html custom-overflow custom-editor" v-html="questionEntityInfo.content"></div>
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="问题类型" prop="content">
              <div class="info-item-html custom-overflow custom-editor" v-html="questionEntityInfo.interact_type"></div>
            </el-form-item>
          </div>
        </div>
        <div class="info-group">
          <div class="info-group-title">回答信息</div>
          <div class="info-row">
            <el-form-item class="info-item" label="用户名" prop="user_name">
              <el-input class="info-item-input" v-model="answerEntityInfo.user_name" disabled />
            </el-form-item>
          </div>
          <div class="info-row">
            <el-form-item class="info-item" label="内容" prop="content">
              <div class="info-item-html custom-overflow custom-editor" v-html="answerEntityInfo.content"></div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>

import nodeServer from '../net/nodeServer'
import { ElMessage, ElMessageBox } from 'element-plus'
import nodeServerInteract from "@/net/nodeServerInteract";
import nodeServerRelease from '@/net/nodeServerRelease'

export default {
  name: 'ManageInteractAnswerInfo',
  components: {

  },
  data () {
    return {
      dictInfo: {},

      pageNum: 1, pageSize: 10,

      queryParams: {
        id: '',
        user_id: '',
        question_id: '',
        examine_status: null,
        is_delete: null,
      },


      pageData: {
        rows: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },

      openDialogFlag: false,
      questionEntityInfo: {},
      answerEntityInfo: {},
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    async init () {
      //  初始化行政区划
      await this.initDictInfo();

      await this.searchBtn();
    },
    resetBtn(){
      this.queryParams =  {
        id: '',
        user_id: '',
        question_id: '',
        examine_status: '',
        is_delete: '',
      }

    },
    async initDictInfo(){
      let res = await nodeServer.regionInfo_getDictInfo();
      if(res.success && res.content){
        this.dictInfo = res.content;
      }
    },
    // 查询
    async searchBtn(){
      let searchParams = {
        pageNum: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        id: this.queryParams.id,
        user_id: this.queryParams.user_id,
        question_id: this.queryParams.question_id,
        examine_status: this.queryParams.examine_status,
        is_delete: this.queryParams.is_delete,
      }
      let res = await nodeServerInteract.interactAnswerInfo_getPageByAdmin(searchParams);
      if(res.success && res.content){
        // 处理数据
        for(let row of res.content.rows ){
          // 审核状态
          row.examine_status_enum = this.getDictNameByDictCode('interact_examine_status', row.examine_status);
          row.is_delete_enum = this.getDictNameByDictCode('common_true_or_false', row.is_delete);

        }

        this.pageData.rows = res.content.rows;
        this.pageData.total = res.content.total;
      }
    },
    currentChange(currentPage){
      this.pageData.currentPage = currentPage;
      this.searchBtn()
    },
    getDictNameByDictCode(dictType, dictCode){
      if(this.dictInfo && this.dictInfo[dictType] && this.dictInfo[dictType][dictCode]){
        return this.dictInfo[dictType][dictCode];
      }
      return '';
    },
    async showAnswerInfo(index, row){
      this.answerEntityInfo = JSON.parse(JSON.stringify(row));
      let res = await nodeServerInteract.interactQuestionInfo_getOneById({
        id: row.question_id,
      })
      if(res.success && res.content){
        this.questionEntityInfo = res.content;

        this.openDialogFlag = true;
      }
    },
    async deleteAnswerInfoBtn(index, row){
      ElMessageBox.confirm(
        `请确认是否删除回答${row.id}，删除后将不可恢复`,
        '删除回答',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          // 调用接口提交
          this.deleteAnswerInfo(row.id);
        })
        .catch(() => {
          // 取消提交
        })
    },
    async deleteAnswerInfo(id){
      let res = await nodeServerInteract.interactAnswerInfo_deleteById({ id: id });
      if(res.success){
        ElMessage.success(`删除成功`);
        await this.searchBtn();
      } else {
        ElMessage.error(`删除失败`);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.manage-interact-answer-info{
  width: 100%;
  height: 100%;
  .table-header{
    height: 120px;
    line-height: 60px;
    display: flex;
    flex-wrap: wrap;
    .query-item{
      margin-left: 64px;
      .query-item-label{
        text-align: right;
      }
      .query-item-input{
        margin-left: 16px;
        width: 120px;
      }
    }
    .btn-group{
      margin-left: 32px;
      .el-button{
        margin-right: 12px;
        margin-bottom: 6px;
      }
    }
  }
  .table-content{
    height: calc(100% - 120px);
    overflow-y: auto;
    .pagination{
      margin-top: 1rem;
      position: relative;
      float: right;
      display: flex;
      .el-pagination{
        margin-right: 32px;
      }
      .total{
        line-height: 40px;
        font-size: 14px;
        margin-right: 24px;
      }
    }
    .success-row{
      color: #00c48f;
    }
    .warning-row{
      color: #f44336;
    }
    .cancel-row{
      color: #ff9f00;
    }
  }
}

//弹窗
.edit-info-dialog-content{
  padding: 0 20px 24px 20px;
  .info-group{
    .info-group-title{
      height: 24px;
      line-height: 22px;
      font-size: 14px;
      border-bottom: 1px solid #8c8c8c;
      margin-bottom: 16px;
    }
    .info-row{
      display: flex;
      .info-item{
        flex: 1;
        margin-right: 32px;
        .tag-input, .keyword-button-new-tag{
          width: 160px !important;
        }
        .el-tag{
          margin-right: 8px;
          margin-bottom: 4px;
        }
        .tip-text{
          font-size: 12px;
          margin-left: 12px;
          color: #8c8c8c;
        }
        .info-item-images{
          display: flex;
          .el-image{
            width: 160px;
            height: 120px;
            margin-right: 24px;
            border-radius: 4px;
          }
        }
        .info-item-html{
          overflow-y: auto;
          overflow-x: auto;
          width: calc(100%);
          background: #f5f7fa;
          color: #a8abb2;
          border-radius: 6px;
          border: solid 1px #e4e7ed;
          padding: 0 8px;
        }
      }
    }
  }
  .btn-group{
    text-align: center;
  }
}
</style>
<style lang="scss">
.edit-info-dialog{
  width: 800px !important;
  .el-dialog__body{
    padding: 0;
  }
}

</style>
