<template>
  <div class="manage-module" >
    <el-container>
      <el-aside class="custom-overflow">
        <el-menu class="el-menu-vertical-demo" :default-active="'0'" @select="handleSelect">
          <template v-for="(item) in menuList">
            <el-menu-item v-if="item.children == null" :index="item.index">
              <i class="iconfont icon-menu"></i>
              <span>{{item.name}}</span>
            </el-menu-item>
            <el-sub-menu v-else :index="item.index">
              <template #title>
                <i class="iconfont icon-menu"></i>
                <span>{{item.name}}</span>
              </template>
              <template v-for="(childrenItem) in item.children">
                <el-menu-item :index="childrenItem.index">
                  <i class="iconfont icon-menu_sub"></i>
                  <span>{{childrenItem.name}}</span>
                </el-menu-item>
              </template>
            </el-sub-menu>
          </template>
        </el-menu>
      </el-aside>
      <el-main>
        <ManageOverviewInfo v-if="selectMenuIndex == '0'"></ManageOverviewInfo>
        <ManageUserInfo v-if="selectMenuIndex == '1'"></ManageUserInfo>
        <ManageApplicationInfo v-if="selectMenuIndex == '2'"></ManageApplicationInfo>
        <ManageOrderInfo v-if="selectMenuIndex == '3'"></ManageOrderInfo>
        <ManageQuestionInfo v-if="selectMenuIndex == '5'"></ManageQuestionInfo>
        <ManageReleaseAuditInfo v-if="selectMenuIndex == '6-1'"></ManageReleaseAuditInfo>
        <ManageReleaseBaseInfo v-if="selectMenuIndex == '6-2'"></ManageReleaseBaseInfo>
        <ManageReleaseStaffInfo v-if="selectMenuIndex == '6-3'"></ManageReleaseStaffInfo>
        <ManageReleaseJobInfo v-if="selectMenuIndex == '6-4'"></ManageReleaseJobInfo>
        <ManageReleaseEvaluateInfo v-if="selectMenuIndex == '6-5'"></ManageReleaseEvaluateInfo>
        <ManageFeedbackInfo v-if="selectMenuIndex == '7'"></ManageFeedbackInfo>
        <ManageInteractAuditInfo v-if="selectMenuIndex == '8-1'"></ManageInteractAuditInfo>
        <ManageInteractQuestionInfo v-if="selectMenuIndex == '8-2'"></ManageInteractQuestionInfo>
        <ManageInteractAnswerInfo v-if="selectMenuIndex == '8-3'"></ManageInteractAnswerInfo>
        <ManageInteractTypeInfo v-if="selectMenuIndex == '8-4'"></ManageInteractTypeInfo>
        <ManageInteractArticleInfo v-if="selectMenuIndex == '8-5'"></ManageInteractArticleInfo>
      </el-main>
    </el-container>
  </div>
</template>

<script>

import ManageOverviewInfo from "./ManageOverviewInfo";
import ManageUserInfo from "./ManageUserInfo";
import ManageApplicationInfo from "./ManageApplicationInfo";
import ManageOrderInfo from "./ManageOrderInfo";
import ManageQuestionInfo from "./ManageQuestionInfo.vue";
import ManageReleaseBaseInfo from '@/views/ManageReleaseBaseInfo.vue'
import ManageReleaseStaffInfo from '@/views/ManageReleaseStaffInfo.vue'
import ManageReleaseJobInfo from '@/views/ManageReleaseJobInfo.vue'
import ManageReleaseAuditInfo from '@/views/ManageReleaseAuditInfo.vue'
import ManageReleaseEvaluateInfo from '@/views/ManageReleaseEvaluateInfo.vue'
import ManageFeedbackInfo from '@/views/ManageFeedbackInfo.vue'
import ManageInteractAuditInfo from "@/views/ManageInteractAuditInfo.vue";
import ManageInteractTypeInfo from '@/views/ManageInteractTypeInfo.vue'
import ManageInteractQuestionInfo from '@/views/ManageInteractQuestionInfo.vue'
import ManageInteractAnswerInfo from '@/views/ManageInteractAnswerInfo.vue'
import ManageInteractArticleInfo from '@/views/ManageInteractArticleInfo.vue'

export default {
  name: 'ManageModule',
  components: {
    ManageInteractArticleInfo,
    ManageInteractAnswerInfo,
    ManageInteractQuestionInfo,
    ManageInteractTypeInfo,
    ManageInteractAuditInfo,
    ManageFeedbackInfo,
    ManageOverviewInfo,
    ManageApplicationInfo,
    ManageUserInfo,
    ManageOrderInfo,
    ManageQuestionInfo,
    ManageReleaseBaseInfo,
    ManageReleaseStaffInfo,
    ManageReleaseJobInfo,
    ManageReleaseAuditInfo,
    ManageReleaseEvaluateInfo,
  },
  data () {
    return {
      menuList: [
        {index: '0', code: 'overviewInfo', name: '总览' },
        {index: '1', code: 'userInfo', name: '用户信息'},
        {index: '2', code: 'applicationInfo', name: '应用信息'},
        {index: '3', code: 'orderInfo', name: '订单信息'},
        {index: '5', code: 'questionInfo', name: '问题答疑维护'},
        {index: '6', code: 'releaseInfo', name: '信息发布维护',
          children: [
            {index: '6-1', code: 'releaseAuditInfo', name: '审核列表'},
            {index: '6-2', code: 'releaseBaseInfo', name: '基本信息'},
            {index: '6-3', code: 'releaseStaffInfo', name: '人员信息'},
            {index: '6-4', code: 'releaseJobInfo', name: '工作信息'},
            {index: '6-5', code: 'releaseEvaluateInfo', name: '评论信息'},
          ]
        },
        {index: '7', code: 'feedbackInfo', name: '反馈意见信息'},
        {index: '8', code: 'IntercatInfo', name: '审计问答维护',
          children: [
            {index: '8-1', code: 'IntercatAuditInfo', name: '审核列表'},
            {index: '8-2', code: 'IntercatQuestionInfo', name: '问题信息'},
            {index: '8-3', code: 'IntercatAnswerInfo', name: '回答信息'},
            {index: '8-4', code: 'IntercatTypeInfo', name: '问题类型'},
            {index: '8-5', code: 'IntercatArticleInfo', name: '编写文章'},
          ]
        },
      ],
      selectMenuIndex: 0
    }
  },
  mounted () {
    this.init()
  },
  beforeUnmount () {
  },
  methods: {
    init () {
    },
    handleSelect(key, keyPath){
      this.selectMenuIndex = key;
    }
  }
}
</script>
<style lang="scss" scoped>
.manage-module{
  width: 100%;
  height: 720px;
  font-size: 14px;
  .el-container{
    height: 100%;
    margin: 32px 240px;
    background: #fff;
    .el-aside{
      width: 240px;
      border-right: solid 2px #dcdfe6;
      .el-menu{
        border-right: none;
        .iconfont{
          margin-right: 6px;
        }
        .el-sub-menu{
          li.el-menu-item{
            margin-left: 16px;
          }
        }
      }
    }
  }
  .el-main{
    padding: 20px;
  }
}
</style>
